import isEmpty from 'lodash/isEmpty'

export const filterMaidsMixin = {
  data: function() {
    return {
      filterData: {
        rating: 0,
        repeatRate: 0,
        totalReviews: 0,
        completeHrs: 0,
        quality: [],
        sortBy: 'rating',
        favoriteMaids: false,
        ratedhighly: false,
        previoslyUsed: false,
        services: null,
        bring_equipment: false,
        canSpeakEnglish: null,
        canSpeakJapanese: null
      },
      params: [
        'limit',
        'page',
        'order',
        'bring_equipment',
        'services',
        'languages',
        'location_latitude',
        'location_longitude',
        'previosly_used',
        'start_datetime',
        'duration'
      ]
    }
  },
  computed: {
    baseOptions: function() {
      const options = {
        limit: 24,
        order: this.filterData.sortBy,
        bring_equipment: this.filterData.bring_equipment,
        services: []
      }
      if (this.filterData.rating !== 0) {
        options.rating = this.filterData.rating
      }

      if (this.filterData.totalReviews !== 0) {
        options.no_of_reviews = this.filterData.totalReviews
      }

      if (this.filterData.repeatRate !== 0) {
        options.repeat_rate = this.filterData.repeatRate
      }

      if (this.filterData.completeHrs !== 0) {
        options.total_hours = this.filterData.completeHrs
      }

      // Services
      const services = []
      if (this.filterData.canIron) {
        services.push(`DRAPERY`)
      }
      options.services = services.join(',')


      // languages
      const languages = []
      if (this.filterData.canSpeakEnglish) {
        languages.push('ENGLISH')
      }
      if (this.filterData.canSpeakJapanese) {
        languages.push('JAPANESE')
      }

      if (!isEmpty(languages)) {
        languages.unshift('THAI')
      }
      options.languages = languages.join(',')

      const quality = this.filterData.quality.toString()
      if (quality) {
        options.quality = quality
      }

      return options
    }
  },
  methods: {
    getMaidListOptions: function() {
      const options = { ...this.baseOptions }
      return options
    },
    getMaidListWithFriendsOptions: function() {
      // console.log('Mixins:: getMaidListWithFriendsOptions', this.filterData)
      // console.log('Mixins:: getMaidListWithFriendsOptions -> params', this.params)
      const options = { ...this.baseOptions }
      options.page = this.currentMaidsFriendPage + 1
      options.favorite_maids = this.filterData.favoriteMaids
      options.rated_highly = this.filterData.ratedhighly
      options.previosly_used = this.filterData.previoslyUsed
      console.log(options)
      return options
    }
  }
}
