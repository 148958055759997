<template>
  <b-container class="container-content form-container">
    <b-alert variant="danger text-center" :show="!isMaidPublished && !isFetchingMaid">{{
      $t('warning.warning_maid_unpublished')
    }}</b-alert>

    <div class="page-header marb8 layout-header">
      <h4 class="page-header-text">
        {{
          isBookingOneTime
            ? $t('pages.booking.book_one_time')
            : $t('pages.booking.one_time_booking')
        }}

        <BookingStatus :booking="booking" :showDescription="true" />
      </h4>

      <div class="layout-dropdown" v-if="(!bookingDetail && !hideMode) || dateMode">
        <b-dropdown :text="dropdownText" right :variant="dropdownVariant" class="custom-dropdown" :class="{ 'selected-item': selected }">
          <b-dropdown-item @click="selectStepOrder('dateFirst')">{{$t('pages.booking.date_before_maid')}}</b-dropdown-item>
          <div class="dropdown-divider"></div>
          <b-dropdown-item @click="selectStepOrder('maidFirst')">{{$t('pages.booking.maid_before_date')}}</b-dropdown-item>
        </b-dropdown>
        <!-- <b-img v-if="selected"
          alt="information"
          src="../../assets/images/information-icon-black.svg"
          id="switch-step-info"
        ></b-img>
        <b-tooltip v-if="selected"
          target="switch-step-info"
          placement="bottom"
          triggers="hover"
          >{{ $t('pages.booking.switch_step_info') }}</b-tooltip
        > -->
      </div>
    </div>

    <b-row>
      <b-col xl="8" offset-xl="2" class="marb8">
        <!-- Equipments -->
        <BookingStepWrapper :step="1">
          <div
            id="not-complete-location"
            v-if="isNotComplete && !bookingForm.location.main_text"
            style="margin-top: 2px; margin-bottom: 2px; font-size: 15.5px"
            class="text-danger"
          >
            {{ $t('warning.please_complete_this_step') }}
          </div>
          <div class="d-block mb-lg-0">
            <address-select
              v-model="bookingForm.location"
              :disabled="disabledAddress"
              :add_details="bookingForm.location_additional_details"
              :prev="prevRoute"
              ref="address"
            ></address-select>

            <!-- Equipment Options -->
            <div
              id="not-complete-equipment"
              v-if="isNotComplete && !bookingForm.equipment"
              style="margin-top: 2px; margin-bottom: 2px; font-size: 15.5px"
              class="text-danger"
            >
              {{ $t('warning.please_complete_this_step') }}
            </div>
            <b-form-group>
              <template v-slot:label>
                {{ $t('form_label.equipment_options') }}
                <b-img
                  alt="information"
                  src="../../assets/images/information-icon-black.svg"
                  style="margin-left: 0.25em; margin-top: -3px"
                  id="equipment-icon-1"
                ></b-img>
                <b-tooltip
                  target="equipment-icon-1"
                  placement="bottom"
                  style="max-width: 100%"
                  custom-class="EquipmentToolTips"
                  >{{ $t('form_label.equipment_description') }}</b-tooltip
                >
              </template>
              <v-select
                :labelTitle="$t('form_label.nothing_selected')"
                class="custom-v-select"
                :options="optionEquipments"
                v-model="bookingForm.equipment"
                :disabled="(!isBookingOneTime || disableChange) && !hasEquipmentError"
              />
            </b-form-group>
            <div
              id="not-complete-services"
              v-if="isNotComplete && !bookingForm.services"
              style="margin-top: 2px; margin-bottom: 2px; font-size: 15.5px"
              class="text-danger"
            >
              {{ $t('warning.please_complete_this_step') }}
            </div>
            <b-form-group :label="$t('form_label.services')">
              <v-select
                :labelTitle="$t('form_label.nothing_selected')"
                class="custom-v-select"
                :options="optionServices"
                v-model="bookingForm.services"
                :disabled="
                  (!isBookingOneTime || disableChange) && isSelectedServiceOptionValidWithMaid
                "
              />
            </b-form-group>
          </div>
        </BookingStepWrapper>
      </b-col>

      <b-col xl="8" offset-xl="2" class="marb8">
        <div v-if="stepOrder === 'dateFirst'">
          <!-- Pick date and time -->
          <BookingStepWrapper :step="2">
            <b-form-row class="custom-input-datetime">
              <div
                id="not-complete-date"
                v-if="isNotComplete && !bookingForm.date"
                style="margin-top: 2px; margin-bottom: 2px; font-size: 15.5px"
                class="text-danger"
              >
                {{ $t('warning.please_complete_this_step') }}
              </div>
              <b-col cols="12" md="12" lg="12" sm="12" xl="12">
                <b-form-group
                  :label="`${
                    isBookingOneTime ? $t('form_label.pick_a_date') : $t('form_label.date')
                  }`"
                >
                  <b-input-group>
                    <b-form-input
                      class="input-date"
                      :placeholder="$t('form_label.pick_a_date_from_calendar')"
                      :class="{ disabled: disableChange }"
                      disabled
                      :value="calendarValue"
                    ></b-form-input>
                    <b-input-group-append>
                      <b-button
                        variant="link"
                        class="input-fa-calendar d-none d-sm-block"
                        :class="{ disabled: disableChange }"
                        disabled
                      >
                        <img :src="require('../../assets/images/icons/datepicker.png')" alt />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <!-- <small>{{ $t('form_label.pick_a_date_from_calendar') }}</small> -->
                </b-form-group>
              </b-col>
              <b-col cols="12" md="12" lg="12" sm="12" xl="12">
                <b-form-group v-if="!isServiceCompleted && !isCanceled" class="mb-0">
                  <div style="position: relative;">
                  <!-- Default Calendar -->
                  <v-calendar
                    :locale="$i18n.locale"
                    ref="calendar"
                    class="v-calendar-custom"
                    :min-date="minDate"
                    :attributes="defaultCalendarAttrs"
                    is-expanded
                    @dayclick="onSelectedDate"
                    @update:from-page="onMonthChanged"
                    v-if="bookingForm.method === 0"
                  ></v-calendar>
  
                  <!-- Maid's Calendar -->
                  <v-calendar
                    :locale="$i18n.locale"
                    ref="calendar"
                    class="v-calendar-custom"
                    :min-date="minDate"
                    :attributes="maidCalendarAttrs"
                    is-expanded
                    @dayclick="onSelectedDate"
                    @update:from-page="onMonthChanged"
                    v-else-if="bookingForm.method === 1"
                  ></v-calendar>
                  <div v-if="bookingForm.method === 1"
                  style="top: 0; right: 50%; position: absolute; transform: translateX(550%);">
                    <b-img
                      :src="require('@/assets/images/information-icon-black.svg')"
                      v-b-tooltip.hover.html="$t('form_label.calendar_tooltip')"
                      @click.stop
                      class="mt-3"
                      style="z-index: 100;"
                    ></b-img>
                  </div>
                  </div>
                  <CalendarDetail
                    v-if="shouldShowCalendarDetail"
                    :selectedDate="selectedDate"
                    :maidCalendars="maidAvailableTimeSlots"
                  />
                </b-form-group>
              </b-col>
              <div
                id="not-complete-time"
                v-if="isNotComplete && !bookingForm.time"
                style="margin-top: 2px; margin-bottom: 2px; font-size: 15.5px"
                class="text-danger"
              >
                {{ $t('warning.please_complete_this_step') }}
              </div>
              <b-col cols="12" md="12" lg="12" sm="12" xl="12">
                <b-form-group :key="timeOptionsKey">
                  <slot name="label">
                    <div style="padding-bottom: calc(0.375rem + 1px); color: #333333">
                      <span class="mr-1">
                        {{ $t('form_label.start') }}
                      </span>
                      <b-img
                        alt="information"
                        src="../../assets/images/information-icon-black.svg"
                        style="margin-top: -2px"
                        id="start-time-badge"
                      ></b-img>
                    </div>
                    <b-tooltip target="start-time-badge" placement="top" triggers="hover">
                      {{ $t('warning.start_time_must_be_at_least_24') }}
                    </b-tooltip>
                  </slot>
                  <v-select
                    v-model="bookingForm.time"
                    :labelTitle="$t('form_label.no_available_time')"
                    class="custom-v-select"
                    :disabled="timeOptionsNothingSelect"
                    v-if="timeOptionsNothingSelect"
                  />
                  <v-select
                    :labelTitle="$t('form_label.nothing_selected')"
                    :options="timeOptions"
                    v-model="bookingForm.time"
                    class="custom-v-select"
                    :disabled="!bookingForm.date || disableChange || isFetchingCalendar"
                    v-else
                  />
                </b-form-group>
              </b-col>
              
              <b-col cols="12" md="12" lg="12" sm="12" xl="12">
                <div
                    id="not-complete-accommodation"
                    v-if="isNotComplete && !bookingForm.accommodation"
                    style="margin-top: 2px; margin-bottom: 2px; font-size: 15.5px"
                    class="text-danger"
                  >
                    {{ $t('warning.please_complete_this_step') }}
                </div>
                <b-form-group :label="$t('form_label.accommodation')">
                  <v-select
                    :labelTitle="$t('form_label.nothing_selected')"
                    class="custom-v-select"
                    :options="optionAccommodation"
                    v-model="bookingForm.accommodation"
                    :disabled="!isBookingOneTime || disableChange"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="12" lg="12" sm="12" xl="12">
                <div
                    id="not-complete-duration"
                    v-if="isNotComplete && !bookingForm.duration"
                    style="margin-top: 2px; margin-bottom: 2px; font-size: 15.5px"
                    class="text-danger"
                  >
                    {{ $t('warning.please_complete_this_step') }}
                </div>
                <b-form-group :label="$t('form_label.duration')">
                  <div
                    style="margin-top: -4px; margin-bottom: 2px"
                    :class="recommendDurationTextClass ? 'text-success' : 'text-danger'"
                  >
                    {{ recommendDurationText }}
                  </div>
                  <v-select
                    :labelTitle="$t('form_label.nothing_selected')"
                    class="custom-v-select"
                    v-model="bookingForm.duration"
                    disabled
                    v-if="isFetchBooking && !timeOptions[0]"
                  />
                  <v-select
                    :labelTitle="$t('form_label.nothing_selected')"
                    class="custom-v-select"
                    :options="durationOptions"
                    v-model="bookingForm.duration"
                    :disabled="disableChange || !timeOptions[0]"
                    ref="durationSelect"
                    v-else
                  />
                </b-form-group>
              </b-col>
            </b-form-row>
          </BookingStepWrapper>

           <!-- Choose Maid -->
          <BookingStepWrapper :step="3">
            <div
                id="not-complete-maid"
                v-if="isNotComplete && bookingForm.method == 1 && !bookingForm.maid"
                style="margin-top: 2px; margin-bottom: 2px; font-size: 15.5px"
                class="text-danger"
              >
                {{ $t('warning.please_complete_this_step') }}
            </div>
            <b-row class="custom-input-datetime mb-lg-0">
              <b-col cols="12" class="marb8">
                <!-- New Booking -->
                <div v-if="isBookingOneTime">
                  <label style="color: black" class="mr-1">
                    {{ $t('form_label.maid_selection_method') }}
                  </label>
                  <div class="tabs-select-maid">
                    <b-tabs pills justified content-class="mt-3" v-model="bookingForm.method">
                      <!-- OTA -->
                      <b-tab :title="$t('form_label.auto_select_maid')">
                        <small>{{ $t('form_label.auto_select_maid_suggestion') }}</small>
                      </b-tab>
  
                      <!-- OTM -->
                      <b-tab :title="$t('form_label.manual_select_maid')">
                        <small>{{ $t('form_label.maid_select_maid_suggestion') }}</small>
                        <div class="mb-4"></div>
  
                        <b-form-group v-if="bookingForm.method">
                          <b-row>
                            <!-- เลือกแม่บ้านแล้ว -->
                            <b-col cols="10" v-if="bookingForm.maid">
                              <maid-detail
                                class="booking"
                                :detail="bookingForm.maid"
                                :isOutOfRadius="isUserLocationOutOfMaidRadius"
                              />
                            </b-col>
  
                            <b-col cols="2" class="text-right d-flex flex-column" style="gap: 1px" v-if="bookingForm.maid && canChangeMaid">
                              <b-link @click="chooseOptionMaid">
                                <i class="far fa-edit pt-1" style="font-size: 1.25em"></i>
                              </b-link>
                              <b-link @click="unselectMaid">
                                <i class="far fa-times-circle pr-1 pt-1" style="font-size: 1.25em"></i>
                              </b-link>
                            </b-col>
  
                            <!-- ยังไม่เลือกแม่บ้าน -->
                            <b-col cols="auto" v-if="!bookingForm.maid">
                              <img
                                class="avatar"
                                src="@/assets/images/avatar_default.jpg"
                                alt
                              />
                            </b-col>
                            <b-col v-if="!bookingForm.maid">
                              <h6>{{ $t('form_label.please_select_the_maid') }}</h6>
                              <b-button
                                variant="success"
                                block
                                @click="chooseMaid"
                                :disabled="
                                  !bookingForm.location.main_text ||
                                  !isSelectBringEquipment ||
                                  !bookingForm.services
                                "
                              >
                                {{ $t('button.choose_maid') }}
                              </b-button>
                            </b-col>
                            <b-col class="d-none d-sm-block" v-if="!bookingForm.maid"></b-col>
                          </b-row>
                        </b-form-group>
                      </b-tab>
                    </b-tabs>
                  </div>
                </div>
                <div v-else>
                  <!-- Booking Unsuccessful -->
                  <div class="tabs-select-maid">
                    <b-tabs
                      pills
                      justified
                      content-class="mt-3"
                      v-model="bookingForm.method"
                      v-show="isBookingUnSuccessful && !isReview"
                    >
                      <!-- OTA -->
                      <b-tab :title="$t('form_label.auto_select_maid')" active>
                        <small v-html="$t('form_label.auto_select_maid_suggestion')"></small>
                      </b-tab>
  
                      <!-- OTM -->
                      <b-tab :title="$t('form_label.manual_select_maid')">
                        <small v-html="$t('form_label.maid_select_maid_suggestion')"></small>
                        <div class="mb-4"></div>
  
                        <b-form-group v-if="bookingForm.method">
                          <b-row>
                            <!-- เลือกแม่บ้านแล้ว -->
                            <b-col v-if="bookingForm.maid" cols="10">
                              <maid-detail
                                class="booking"
                                :detail="bookingForm.maid"
                                :hasButton="showChatBtn"
                                :disableButton="isServiceCompleted"
                                :buttonText="$t('button.call_maid')"
                                :isOutOfRadius="isUserLocationOutOfMaidRadius"
                                @onClickButton="toCallMaid"
                              ></maid-detail>
                            </b-col>
  
                            <!-- Case: Manual Select (Maid Reject) -->
                            <b-col class="text-right" v-if="bookingForm.maid">
                              <b-link @click="chooseOptionMaid" v-if="canChangeMaid && bookingForm.maid">
                                <i class="far fa-edit" style="font-size: 1.25em"></i>
                              </b-link>
                            </b-col>
  
                            <!-- User want to change from OTA to OTM -->
                            <b-col cols="auto" v-if="!bookingForm.maid">
                              <img
                                class="avatar"
                                src="@/assets/images/avatar_default.jpg"
                              />
                            </b-col>
                            <b-col v-if="!bookingForm.maid">
                              <h6>{{ $t('form_label.please_select_the_maid') }}</h6>
                              <b-button variant="success" block @click="chooseMaid">
                                {{ $t('button.choose_maid') }}
                              </b-button>
                            </b-col>
                            <b-col class="d-none d-sm-block" v-if="!bookingForm.maid"></b-col>
                          </b-row>
                        </b-form-group>
                      </b-tab>
                    </b-tabs>
                    <!-- <div class="tabs-select-maid" v-show="isReview">
                    <p
                      v-html="selectionMethodLabel"
                      v-if="!bookingForm.maid && !isBookingUnSuccessful"
                    ></p>
                    <div></div>
                  </div> -->
                  </div>
  
                  <!-- Booking Detail Actions -->
                  <b-form-group v-if="!isBookingUnSuccessful">
                    <!-- วิธีการเลือกแม่บ้าน -->
  
                    <p v-html="selectionMethodLabel"></p>
  
                    <b-row>
                      <b-col cols="10" v-if="bookingForm.maid">
                        <maid-detail
                          class="booking"
                          :detail="bookingForm.maid"
                          :hasButton="showChatBtn"
                          :disableButton="isServiceCompleted"
                          :buttonText="$t('button.call_maid')"
                          :isOutOfRadius="isUserLocationOutOfMaidRadius"
                          @onClickButton="toCallMaid"
                        ></maid-detail>
                      </b-col>
  
                      <!-- Case: Auto Select  -->
                      <b-col cols="auto" v-if="!bookingForm.maid">
                        <img
                          class="avatar"
                          src="@/assets/images/avatar_default.jpg"
                        />
                      </b-col>
                      <b-col v-if="!bookingForm.maid && isTimeoutOfAppproval">
                        <!-- 2020-10-22: ซ่อนปุ่มในเคสที่ยังไม่มีแม่บ้านรับ -->
                        <h6 v-if="false">
                          {{ $t('form_label.please_select_the_maid') }}
                        </h6>
                        <b-button variant="success" block @click="chooseMaid" v-if="false">
                          {{ $t('button.choose_maid') }}
                        </b-button>
                      </b-col>
                      <b-col class="d-none d-sm-block" v-if="!bookingForm.maid"></b-col>
  
                      <!-- Case: Manual Select (Maid Reject) -->
                      <b-col class="text-right" v-if="canChangeMaid && bookingForm.maid">
                        <b-link @click="chooseOptionMaid">
                          <i class="far fa-edit" style="font-size: 1.25em"></i>
                        </b-link>
                      </b-col>
                    </b-row>
                  </b-form-group>
                  <b-form-group v-else-if="isReview">
                    <!-- วิธีการเลือกแม่บ้าน -->
  
                    <p v-html="selectionMethodLabel"></p>
  
                    <b-row>
                      <b-col cols="10" v-if="bookingForm.maid">
                        <maid-detail
                          class="booking"
                          :detail="bookingForm.maid"
                          :hasButton="false"
                        ></maid-detail>
                      </b-col>
  
                      <!-- Case: Auto Select  -->
                      <b-col cols="auto" v-if="!bookingForm.maid">
                        <img
                          class="avatar"
                          src="@/assets/images/avatar_default.jpg"
                        />
                      </b-col>
                      <b-col v-if="!bookingForm.maid && isTimeoutOfAppproval">
                        <!-- 2020-10-22: ซ่อนปุ่มในเคสที่ยังไม่มีแม่บ้านรับ -->
                        <h6 v-if="false">
                          {{ $t('form_label.please_select_the_maid') }}
                        </h6>
                        <b-button variant="success" block @click="chooseMaid" v-if="false">
                          {{ $t('button.choose_maid') }}
                        </b-button>
                      </b-col>
                      <b-col class="d-none d-sm-block" v-if="!bookingForm.maid"></b-col>
  
                      <!-- Case: Manual Select (Maid Reject) -->
                    </b-row>
                  </b-form-group>
                </div>
              </b-col>
            </b-row>
            <!-- Errors Text -->
            <ErrorsMaidMisMatch v-if="bookingForm.maid" :errors="[equipmentError, notProvidedServicesError , isUserLocationOutOfMaidRadius ? $t('warning.maid_out_of_radius') : '', isaAllergicMaid]" />
          </BookingStepWrapper>
        </div>

        <div v-else-if="stepOrder === '' || stepOrder === 'maidFirst' || (bookingDetail === 'MyBooking' && !dateMode) || hideMode">
           <!-- Choose Maid -->
          <BookingStepWrapper :step="2">
            <div
                id="not-complete-maid"
                v-if="isNotComplete && bookingForm.method == 1 && !bookingForm.maid"
                style="margin-top: 2px; margin-bottom: 2px; font-size: 15.5px"
                class="text-danger"
              >
                {{ $t('warning.please_complete_this_step') }}
            </div>
            <b-row class="custom-input-datetime mb-lg-0">
              <b-col cols="12" class="marb8">
                <!-- New Booking -->
                <div v-if="isBookingOneTime">
                  <label style="color: black" class="mr-1">
                    {{ $t('form_label.maid_selection_method') }}
                  </label>
                  <div class="tabs-select-maid">
                    <b-tabs pills justified content-class="mt-3" v-model="bookingForm.method">
                      <!-- OTA -->
                      <b-tab :title="$t('form_label.auto_select_maid')">
                        <small>{{ $t('form_label.auto_select_maid_suggestion') }}</small>
                      </b-tab>
  
                      <!-- OTM -->
                      <b-tab :title="$t('form_label.manual_select_maid')">
                        <small>{{ $t('form_label.maid_select_maid_suggestion') }}</small>
                        <div class="mb-4"></div>
  
                        <b-form-group v-if="bookingForm.method">
                          <b-row>
                            <!-- เลือกแม่บ้านแล้ว -->
                            <b-col cols="10" v-if="bookingForm.maid">
                              <maid-detail
                                class="booking"
                                :detail="bookingForm.maid"
                                :isOutOfRadius="isUserLocationOutOfMaidRadius"
                              />
                            </b-col>
  
                            <b-col class="text-right" v-if="bookingForm.maid && canChangeMaid">
                              <b-link @click="chooseOptionMaid">
                                <i class="far fa-edit" style="font-size: 1.25em"></i>
                              </b-link>
                            </b-col>
  
                            <!-- ยังไม่เลือกแม่บ้าน -->
                            <b-col cols="auto" v-if="!bookingForm.maid">
                              <img
                                class="avatar"
                                src="@/assets/images/avatar_default.jpg"
                                alt
                              />
                            </b-col>
                            <b-col v-if="!bookingForm.maid">
                              <h6>{{ $t('form_label.please_select_the_maid') }}</h6>
                              <b-button
                                variant="success"
                                block
                                @click="chooseMaid"
                                :disabled="
                                  !bookingForm.location.main_text ||
                                  !isSelectBringEquipment ||
                                  !bookingForm.services
                                "
                              >
                                {{ $t('button.choose_maid') }}
                              </b-button>
                            </b-col>
                            <b-col class="d-none d-sm-block" v-if="!bookingForm.maid"></b-col>
                          </b-row>
                        </b-form-group>
                      </b-tab>
                    </b-tabs>
                  </div>
                </div>
                <div v-else>
                  <!-- Booking Unsuccessful -->
                  <div class="tabs-select-maid">
                    <b-tabs
                      pills
                      justified
                      content-class="mt-3"
                      v-model="bookingForm.method"
                      v-show="isBookingUnSuccessful && !isReview"
                    >
                      <!-- OTA -->
                      <b-tab :title="$t('form_label.auto_select_maid')" active>
                        <small v-html="$t('form_label.auto_select_maid_suggestion')"></small>
                      </b-tab>
  
                      <!-- OTM -->
                      <b-tab :title="$t('form_label.manual_select_maid')">
                        <small v-html="$t('form_label.maid_select_maid_suggestion')"></small>
                        <div class="mb-4"></div>
  
                        <b-form-group v-if="bookingForm.method">
                          <b-row>
                            <!-- เลือกแม่บ้านแล้ว -->
                            <b-col v-if="bookingForm.maid" cols="10">
                              <maid-detail
                                class="booking"
                                :detail="bookingForm.maid"
                                :hasButton="showChatBtn"
                                :disableButton="isServiceCompleted"
                                :buttonText="$t('button.call_maid')"
                                :isOutOfRadius="isUserLocationOutOfMaidRadius"
                                @onClickButton="toCallMaid"
                              ></maid-detail>
                            </b-col>
  
                            <!-- Case: Manual Select (Maid Reject) -->
                            <b-col class="text-right" v-if="bookingForm.maid">
                              <b-link @click="chooseOptionMaid" v-if="canChangeMaid && bookingForm.maid">
                                <i class="far fa-edit" style="font-size: 1.25em"></i>
                              </b-link>
                            </b-col>
  
                            <!-- User want to change from OTA to OTM -->
                            <b-col cols="auto" v-if="!bookingForm.maid">
                              <img
                                class="avatar"
                                src="@/assets/images/avatar_default.jpg"
                              />
                            </b-col>
                            <b-col v-if="!bookingForm.maid">
                              <h6>{{ $t('form_label.please_select_the_maid') }}</h6>
                              <b-button variant="success" block @click="chooseMaid">
                                {{ $t('button.choose_maid') }}
                              </b-button>
                            </b-col>
                            <b-col class="d-none d-sm-block" v-if="!bookingForm.maid"></b-col>
                          </b-row>
                        </b-form-group>
                      </b-tab>
                    </b-tabs>
                    <!-- <div class="tabs-select-maid" v-show="isReview">
                    <p
                      v-html="selectionMethodLabel"
                      v-if="!bookingForm.maid && !isBookingUnSuccessful"
                    ></p>
                    <div></div>
                  </div> -->
                  </div>
  
                  <!-- Booking Detail Actions -->
                  <b-form-group v-if="!isBookingUnSuccessful">
                    <!-- วิธีการเลือกแม่บ้าน -->
  
                    <p v-html="selectionMethodLabel"></p>
  
                    <b-row>
                      <b-col cols="10" v-if="bookingForm.maid">
                        <maid-detail
                          class="booking"
                          :detail="bookingForm.maid"
                          :hasButton="showChatBtn"
                          :disableButton="isServiceCompleted"
                          :buttonText="$t('button.call_maid')"
                          :isOutOfRadius="isUserLocationOutOfMaidRadius"
                          @onClickButton="toCallMaid"
                        ></maid-detail>
                      </b-col>
  
                      <!-- Case: Auto Select  -->
                      <b-col cols="auto" v-if="!bookingForm.maid">
                        <img
                          class="avatar"
                          src="@/assets/images/avatar_default.jpg"
                        />
                      </b-col>
                      <b-col v-if="!bookingForm.maid && isTimeoutOfAppproval">
                        <!-- 2020-10-22: ซ่อนปุ่มในเคสที่ยังไม่มีแม่บ้านรับ -->
                        <h6 v-if="false">
                          {{ $t('form_label.please_select_the_maid') }}
                        </h6>
                        <b-button variant="success" block @click="chooseMaid" v-if="false">
                          {{ $t('button.choose_maid') }}
                        </b-button>
                      </b-col>
                      <b-col class="d-none d-sm-block" v-if="!bookingForm.maid"></b-col>
  
                      <!-- Case: Manual Select (Maid Reject) -->
                      <b-col class="text-right" v-if="canChangeMaid && bookingForm.maid">
                        <b-link @click="chooseOptionMaid">
                          <i class="far fa-edit" style="font-size: 1.25em"></i>
                        </b-link>
                      </b-col>
                    </b-row>
                  </b-form-group>
                  <b-form-group v-else-if="isReview">
                    <!-- วิธีการเลือกแม่บ้าน -->
  
                    <p v-html="selectionMethodLabel"></p>
  
                    <b-row>
                      <b-col cols="10" v-if="bookingForm.maid">
                        <maid-detail
                          class="booking"
                          :detail="bookingForm.maid"
                          :hasButton="false"
                        ></maid-detail>
                      </b-col>
  
                      <!-- Case: Auto Select  -->
                      <b-col cols="auto" v-if="!bookingForm.maid">
                        <img
                          class="avatar"
                          src="@/assets/images/avatar_default.jpg"
                        />
                      </b-col>
                      <b-col v-if="!bookingForm.maid && isTimeoutOfAppproval">
                        <!-- 2020-10-22: ซ่อนปุ่มในเคสที่ยังไม่มีแม่บ้านรับ -->
                        <h6 v-if="false">
                          {{ $t('form_label.please_select_the_maid') }}
                        </h6>
                        <b-button variant="success" block @click="chooseMaid" v-if="false">
                          {{ $t('button.choose_maid') }}
                        </b-button>
                      </b-col>
                      <b-col class="d-none d-sm-block" v-if="!bookingForm.maid"></b-col>
  
                      <!-- Case: Manual Select (Maid Reject) -->
                    </b-row>
                  </b-form-group>
                </div>
              </b-col>
            </b-row>
            <!-- Errors Text -->
            <ErrorsMaidMisMatch v-if="bookingForm.maid" :errors="[equipmentError, notProvidedServicesError , isUserLocationOutOfMaidRadius ? $t('warning.maid_out_of_radius') : '', isaAllergicMaid]" />
          </BookingStepWrapper>

          <!-- Pick date and time -->
          <BookingStepWrapper :step="3">
            <b-form-row class="custom-input-datetime">
              <div
                id="not-complete-date"
                v-if="isNotComplete && !bookingForm.date"
                style="margin-top: 2px; margin-bottom: 2px; font-size: 15.5px"
                class="text-danger"
              >
                {{ $t('warning.please_complete_this_step') }}
              </div>
              <b-col cols="12" md="12" lg="12" sm="12" xl="12">
                <b-form-group
                  :label="`${
                    isBookingOneTime ? $t('form_label.pick_a_date') : $t('form_label.date')
                  }`"
                >
                  <b-input-group>
                    <b-form-input
                      class="input-date"
                      :placeholder="$t('form_label.pick_a_date_from_calendar')"
                      :class="{ disabled: disableChange }"
                      disabled
                      :value="calendarValue"
                    ></b-form-input>
                    <b-input-group-append>
                      <b-button
                        variant="link"
                        class="input-fa-calendar d-none d-sm-block"
                        :class="{ disabled: disableChange }"
                        disabled
                      >
                        <img :src="require('../../assets/images/icons/datepicker.png')" alt />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <!-- <small>{{ $t('form_label.pick_a_date_from_calendar') }}</small> -->
                </b-form-group>
              </b-col>
              <b-col cols="12" md="12" lg="12" sm="12" xl="12">
                <b-form-group v-if="!isServiceCompleted && !isCanceled" class="mb-0">
                  <div style="position: relative;">
                  <!-- Default Calendar -->
                  <v-calendar
                    :locale="$i18n.locale"
                    ref="calendar"
                    class="v-calendar-custom"
                    :min-date="minDate"
                    :attributes="defaultCalendarAttrs"
                    is-expanded
                    @dayclick="onSelectedDate"
                    @update:from-page="onMonthChanged"
                    v-if="bookingForm.method === 0"
                  ></v-calendar>
  
                  <!-- Maid's Calendar -->
                  <v-calendar
                    :locale="$i18n.locale"
                    ref="calendar"
                    class="v-calendar-custom"
                    :min-date="minDate"
                    :attributes="maidCalendarAttrs"
                    is-expanded
                    @dayclick="onSelectedDate"
                    @update:from-page="onMonthChanged"
                    v-else-if="bookingForm.method === 1"
                  ></v-calendar>
                  <div v-if="bookingForm.method === 1"
                  style="top: 0; right: 50%; position: absolute; transform: translateX(550%);">
                    <b-img
                      :src="require('@/assets/images/information-icon-black.svg')"
                      v-b-tooltip.hover.html="$t('form_label.calendar_tooltip')"
                      @click.stop
                      class="mt-3"
                      style="z-index: 100;"
                    ></b-img>
                  </div>
                  </div>
                  <CalendarDetail
                    v-if="shouldShowCalendarDetail"
                    :selectedDate="selectedDate"
                    :maidCalendars="maidAvailableTimeSlots"
                  />
                </b-form-group>
              </b-col>
              <div
                id="not-complete-time"
                v-if="isNotComplete && !bookingForm.time"
                style="margin-top: 2px; margin-bottom: 2px; font-size: 15.5px"
                class="text-danger"
              >
                {{ $t('warning.please_complete_this_step') }}
              </div>
              <b-col cols="12" md="12" lg="12" sm="12" xl="12">
                <b-form-group :key="timeOptionsKey">
                  <slot name="label">
                    <div style="padding-bottom: calc(0.375rem + 1px); color: #333333">
                      <span class="mr-1">
                        {{ $t('form_label.start') }}
                      </span>
                      <b-img
                        alt="information"
                        src="../../assets/images/information-icon-black.svg"
                        style="margin-top: -2px"
                        id="start-time-badge"
                      ></b-img>
                    </div>
                    <b-tooltip target="start-time-badge" placement="top" triggers="hover">
                      {{ $t('warning.start_time_must_be_at_least_24') }}
                    </b-tooltip>
                  </slot>
                  <v-select
                    v-model="bookingForm.time"
                    :labelTitle="$t('form_label.no_available_time')"
                    class="custom-v-select"
                    :disabled="timeOptionsNothingSelect"
                    v-if="timeOptionsNothingSelect"
                  />
                  <v-select
                    :labelTitle="$t('form_label.nothing_selected')"
                    :options="timeOptions"
                    v-model="bookingForm.time"
                    class="custom-v-select"
                    :disabled="!bookingForm.date || disableChange || isFetchingCalendar"
                    v-else
                  />
                </b-form-group>
              </b-col>
            </b-form-row>
          </BookingStepWrapper>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col xl="8" offset-xl="2">
        <BookingStepWrapper :step="4">
          <div
              id="not-complete-accommodation"
              v-if="isNotComplete && !bookingForm.accommodation && stepOrder !== 'dateFirst'"
              style="margin-top: 2px; margin-bottom: 2px; font-size: 15.5px"
              class="text-danger"
            >
              {{ $t('warning.please_complete_this_step') }}
          </div>
          <b-form-group :label="$t('form_label.accommodation')" v-if="stepOrder !== 'dateFirst'">
            <v-select
              :labelTitle="$t('form_label.nothing_selected')"
              class="custom-v-select"
              :options="optionAccommodation"
              v-model="bookingForm.accommodation"
              :disabled="!isBookingOneTime || disableChange"
            />
          </b-form-group>
          <div
              id="not-complete-duration"
              v-if="isNotComplete && !bookingForm.duration && stepOrder !== 'dateFirst'"
              style="margin-top: 2px; margin-bottom: 2px; font-size: 15.5px"
              class="text-danger"
            >
              {{ $t('warning.please_complete_this_step') }}
          </div>
          <b-form-group :label="$t('form_label.duration')" v-if="stepOrder !== 'dateFirst'">
            <div
              style="margin-top: -4px; margin-bottom: 2px"
              :class="recommendDurationTextClass ? 'text-success' : 'text-danger'"
            >
              {{ recommendDurationText }}
            </div>
            <v-select
              :labelTitle="$t('form_label.nothing_selected')"
              class="custom-v-select"
              v-model="bookingForm.duration"
              disabled
              v-if="isFetchBooking && !timeOptions[0]"
            />
            <v-select
              :labelTitle="$t('form_label.nothing_selected')"
              class="custom-v-select"
              :options="durationOptions"
              v-model="bookingForm.duration"
              :disabled="disableChange || !timeOptions[0]"
              ref="durationSelect"
              v-else
            />
          </b-form-group>

          <b-form-group :label="$t('form_label.additional_info')">
            <b-form-checkbox-group v-model="bookingForm.additional" :disabled="isServiceCompleted">
              <b-form-checkbox
                :disabled="disableChange"
                v-for="option in options.additional"
                :key="option.text"
                :value="option.value"
                >{{ $t(`check_box.${option.text}`) }}</b-form-checkbox
              >
            </b-form-checkbox-group>
          </b-form-group>
          <b-form-group>
            <b-textarea
              :placeholder="$t('form_label.additional_placeholder')"
              rows="4"
              v-model="bookingForm.description"
              :disabled="disableChange"
              v-if="!isHistoryBooking || !isCanceled"
            ></b-textarea>
            <p v-else>{{ bookingForm.description }}</p>
            <p v-if="generateErrIsTrue" class="text-danger mt-1">
              {{ $t('pages.booking.the_maximum_length') }}
            </p>
          </b-form-group>

          <div>
            <div class="d-flex justify-content-between mb-3" v-if="isCanceled">
              <p class="m-0">
                {{ $t('form_label.refund') }}
              </p>
              <p class="m-0 text-dark">
                ฿{{
                  bookingForm.payment
                    ? bookingForm.payment.total_price * 0.95
                    : 0 | toCurrencyNumber(true)
                }}
              </p>
            </div>
            <div class="d-flex justify-content-between mb-3" v-if="isCanceled">
              <p class="m-0">
                {{ $t('form_label.cancellation_fee') }}
              </p>
              <p class="m-0 text-dark">
                ฿{{
                  bookingForm.payment
                    ? bookingForm.payment.total_price * 0.05
                    : 0 | toCurrencyNumber(true)
                }}
              </p>
            </div>
            <div class="d-flex justify-content-between" v-if="!isCanceled && grandTotal > 0">
              <p class="m-0">
                {{ $t('alias.total') }}
              </p>
              <p class="m-0 text-dark">฿{{ grandTotal | toCurrencyNumber(true) }}</p>
            </div>
            <hr />
            <div v-if="isBookingOneTime">
              <div
                id="not-complete-policy"
                v-if="isNotComplete && !isAgreePolicy"
                style="margin-top: 2px; margin-bottom: 2px; font-size: 15.5px"
                class="text-danger"
              >
                {{ $t('warning.please_complete_this_step') }}
              </div>
              <div>
                <b-form-checkbox
                  id="agreement-checkbox"
                  v-model="isAgreePolicy"
                  :value="true"
                  :unchecked-value="false"
                  class="my-2"
                >
                  <span>{{ $t('form_label.i_understand_that_bluuu') }}</span>
                  <span @click="openServiceModal('Terms')" style="cursor: pointer">
                    {{ $t('form_label.terms_and_conditions_of_use') }}
                  </span>
                  <span>{{ $t('form_label.and') }}</span>
                  <span @click="openServiceModal('Policy')" style="cursor: pointer">
                    {{ $t('form_label.scope_of_service') }}
                  </span>
                </b-form-checkbox>
              </div>
              <b-button
                variant="success"
                size="md"
                class="py-2 mt-3"
                :class="{ disabled : (!canBooking && !isFetching) }"
                block
                @click="bookNow"
                >{{ $t('button.book_now') }}</b-button
              >
            </div>
            <div v-else>
              <b-form-row v-if="!isServiceCompleted && !isCanceled && finishPrepareData">
                <b-col>
                  <b-button
                    variant="danger"
                    class="py-2"
                    block
                    @click="toggleCancelModal"
                    :disabled="upcomingInOneDay || isReview"
                    >{{ $t('button.cancel_booking') }}</b-button
                  >
                </b-col>
                <b-col>
                  <b-button
                    variant="success"
                    class="py-2"
                    block
                    @click="doUpdateBooking"
                    :disabled="!canUpdateBooking || upcomingInOneDay"
                    >{{ $t('button.confirm_update') }}</b-button
                  >
                </b-col>
              </b-form-row>
              <div class="my-4 d-flex justify-content-center align-items-center">
                <button @click="onClose" class="btn-close">
                  <i class="fas fa-times" style="font-size: 1.25em"></i>
                  {{ $t('button.close') }}
                </button>
              </div>
            </div>
          </div>
        </BookingStepWrapper>
      </b-col>
    </b-row>
    <BaseModal id="warning-invalid-datetime" >
      <div class="text-center">
        <p>{{ $t('error.invalid_datetime') }}</p>
        <p class="text-timestamp">{{ dateTimeTimezone }}</p>
      </div>
    </BaseModal>

    <BaseModal id="warning-cannotselectday" size="lg">
      <div class="text-center py-5">
        <p>{{ $t('warning.you_have_booked_within_24_hour') }} {{ time_can_pay }}</p>
      </div>
    </BaseModal>

    <BaseModal id="agreement-modal" size="lg">
      <PoliciesContent v-if="contentType === `Terms`" />
      <ServicesContent v-if="contentType === `Policy`" />
    </BaseModal>

    <sign-in-modal></sign-in-modal>

    <BaseModal id="cancel-booking-modal">
      <p class="my-2">{{ $t('warning.are_you_sure_to_cancel') }}</p>
      <p>{{ $t('warning.if_you_need_change_booking') }}</p>
      <p>{{ $t('warning.if_you_cancel') }}</p>
      <b-button variant="danger" block class="py-2" @click="doCancelBooking">
        {{ $t('button.cancel_booking') }}
      </b-button>
    </BaseModal>

    <BaseModal id="warning-maid-available-modal">
      <div v-if="warningModalType === 'maid_high_demand'">
        <p class="my-2 text-center" v-html="$t('no_data.maid_high_demand')"></p>
        <b-button variant="primary" block class="py-2" @click="continueBookNow">
          {{ $t('button.close') }}
        </b-button>
      </div>
      <div v-if="warningModalType === 'notice_maid_availability'">
        <p
          class="my-2 text-center"
          v-html="$t('no_data.we_have_noticed_that_maid_availability')"
        ></p>
        <b-button variant="primary" block class="py-2" @click="continueBookNow">
          {{ $t('button.continue') }}
        </b-button>
      </div>
    </BaseModal>
    <BaseModal id="maid-phone-number">
      <div class="text-center py-5" v-if="bookingForm.maid">
        <p class="h5 text-primary">
          {{ $t('modal.maid_phone_number') }} : {{ bookingForm.maid.user.phone }}
        </p>
      </div>
    </BaseModal>

    <BaseModal
      id="confirm-condo-modal"
      :noCloseOnBackdrop="true"
      :noCloseOnEsc="true"
      :hideCloseButton="true"
    >
      <div class="text-center mt-3">
        <p>{{ $t('warning.condo_warning') }}</p>
        <b-form-checkbox
          v-if="isLoggedIn"
          id="confirm-condo-checkbox"
          v-model="dontShowCondoWarning"
          :value="true"
          :unchecked-value="false"
        >
          <span>{{ $t('warning.dont_show_again') }}</span>
        </b-form-checkbox>
        <b-button variant="primary" class="py-2 px-4 mt-3" @click="confirmCondoWarning">
          {{ $t('button.confirm') }}
        </b-button>
      </div>
    </BaseModal>

    <!-- <BaseModal id="warning-option-change-maid">
      <div class="d-flex flex-column">
        <button class="btn btn-outline-primary w-full my-1" @click="optionChangeMaid('maid')">
          {{$t('pages.booking.maid_before_date')}}
        </button>
        <button class="btn btn-outline-primary w-full my-1" @click="optionChangeMaid('date')">
          {{$t('pages.booking.date_before_maid')}}
        </button>
      </div>
    </BaseModal> -->

    <FullContentLoader />
  </b-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { Vue, Component, Watch } from 'vue-property-decorator'
import ApiClient from '../../services'
import VSelect from '@alfsnd/vue-bootstrap-select'
import AddressSelect from '../../components/AddressSelect'
import MaidDetail from '../../components/MaidDetail'
import Constant, { ENABLE_FILTER_MAID_BY_USER_LOCATION, STATUS } from '../../config/booking'
import moment from 'moment'
import BaseModal from '../../components/Modal/BaseModal'
import SignInModal from '../../components/Modal/SignInModal'
import ServicesContent from '../../components/Modal/ServicesContent'
import PoliciesContent from '../../components/Modal/PoliciesContent'
import CalendarDetail from '../../components/CalendarDetail'
import BookingStatus from '../../components/BookingStatus'
import BookingStepWrapper from '../../components/BookingStepWrapper'
import { equipmentMixin } from '../../mixins/booking/equipmentMixin'
import { serviceMixin } from '../../mixins/booking/serviceMixin'

import { isToday, minimumBookingMoment, isAuthenticated, clone } from '../../utils'

import { getDisabledDateFromTimeSlot, isDateInTimeSlots } from '../../helpers/calendar'
import FullContentLoader from '../../components/FullContentLoader'
import ErrorsMaidMisMatch from '../../components/ErrorsMaidMisMatch'

import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { calculateDeductPrice } from '../../helpers/pricing'

import { v4 as uuidv4 } from 'uuid'

const BookingStore = createNamespacedHelpers('booking')
const AuthStore = createNamespacedHelpers('auth')
const CalendarStore = createNamespacedHelpers('calendar')
const MaidStore = createNamespacedHelpers('maid')
const UserStore = createNamespacedHelpers("user");

Component.registerHooks(['beforeRouteEnter'])

@Component({
  components: {
    VSelect,
    AddressSelect,
    MaidDetail,
    BaseModal,
    ServicesContent,
    PoliciesContent,
    CalendarDetail,
    BookingStatus,
    FullContentLoader,
    SignInModal,
    BookingStepWrapper,
    ErrorsMaidMisMatch,
  },
  computed: {
    ...BookingStore.mapState([
      'oneTime',
      'myBookingOneTime',
      'currentBookingOneTime',
      'booking_raw',
      'bookingReview',
    ]),
    ...BookingStore.mapGetters(['getStepOrder']),
    ...AuthStore.mapState(['isLoggedIn', 'callBackPathAfterWelcomePage', 'isNewUser']),
    ...CalendarStore.mapGetters([
      'calendarTotalHours',
      'baseDisabledHighlight',
      'maidAvailableTimeSlots',
    ]),
    ...CalendarStore.mapState(['isFetchingCalendar']),
    ...MaidStore.mapState(['maidsObject']),
    ...UserStore.mapState(["accommodation_confirmed"]),
    generateErrIsTrue() {
      let x = this.bookingForm.description
      if (x) {
        if (x.length <= 500) {
          return false
        } else {
          x = x.slice(0, 499)
          this.bookingForm.description = x
          return true
        }
      }
    },
  },
  methods: {
    ...BookingStore.mapMutations([
      'updateBookingOnetime',
      'setBookingReview',
      'setPaymentBody',
      'setBookingOnetime',
    ]),
    ...BookingStore.mapActions(['updateStepOrder']),
    ...MaidStore.mapActions(['inquiryMaidOutOfRadius']),
    ...AuthStore.mapActions(['signIn']),
    ...CalendarStore.mapMutations([
      'resetMaidCalendar',
      'setCurrentSelectedOnCalendar',
      'setCurrentBookingId',
      'setMaidID',
    ]),
    ...AuthStore.mapMutations(['setCallBackPathAfterWelcomePage', 'addCaldendar']),
    ...CalendarStore.mapActions([
      'fetchMaidCalendarTotalHours',
      'fetchMaidCalendarAllMonth',
      'fetchMaidCalendarAllMonthPublic',
      'isAvailableTimeSlot',
    ]),
    ...UserStore.mapActions(["updateUserAccommodation"]),
    selectStepOrder(order) {
      console.log("selectStepOrder called with:", order);
      this.stepOrder = order;
      localStorage.setItem('stepOrder', order);
      this.switch = true;
      this.updateDropdown();

      if(this.bookingForm.maid && this.stepOrder === 'dateFirst') {
        this.bookingForm.maid = null;
      }
    },
    updateDropdown() {
      console.log("Updating dropdown with stepOrder:", this.stepOrder);
      if (this.stepOrder === 'dateFirst') {
        this.dropdownText = this.$t('pages.booking.date_before_maid_selected');
        this.dropdownVariant = 'primary';
        this.selected = true;
      } else if (this.stepOrder === 'maidFirst') {
        this.dropdownText = this.$t('pages.booking.maid_before_date_selected');
        this.dropdownVariant = 'primary';
        this.selected = true;

        // this.bookingForm.method = 1
      } else if (this.stepOrder === 'hideStep') {
        this.hideMode = true;
      }

      // if(this.switch){
      //   this.bookingForm.bookingCalendars = [];
      //   this.maidCalendarBookingTotalHours = [];
      //   this.disabledDates = []
      //   this.selectedTime = 'Start';
      //   this.selectedDate = '';
      //   this.bookingForm.date = null
      //   this.bookingForm.time = null
      //   this.bookingForm.duration = null
      //   this.resetMaidCalendar();
      // }
    },
  },
  mixins: [equipmentMixin, serviceMixin],
})
export default class BookOneTime extends Vue {
  options = {
    accommodation: Constant.ACCOMMODATION,
    services: Constant.SERVICES,
    additional: Constant.ADDITIONAL_INFO,
    time: Constant.TIME_OPTIONS,
  }

  data() {
    return {
      stepOrder: '',
      dropdownText: this.$t('pages.booking.switch_step'),
      dropdownVariant: 'primary',
      selected: false,
      bookingDetail: this.$route.query.callback,
      dateMode: false,
      switch: false,
      hideMode: false,
    };
  }

  get optionAccommodation() {
    return Constant.ACCOMMODATION.map((option) => ({
      ...option,
      text: this.$t(`accommodation.${option.text}`),
    }))
  }

  get optionServices() {
    return Constant.SERVICES.map((option) => ({
      ...option,
      text: this.$t(`services.${option.text}`),
    }))
  }

  get selectionMethodLabel() {
    if (this.disableChange && this.bookingForm.maid) {
      return ``
      // return this.$t("form_label.maid");
    }

    return (!this.isBookingOneTime && !this.bookingForm.maid) || this.isReview
      ? this.$t('form_label.auto_select_wating_for_confirm')
      : ``
  }

  get isMyBookingOneTime() {
    return this.route === 'MyBookingOneTime'
  }

  ohohoh = false
  generateErr = false
  payments = []
  canSelectDate = true
  time_can_pay = null
  publish_status = 'PUBLISHED'
  // ------------------------
  logDisableDate = null
  otaHasMaid = false
  selectedTime = 'Start'
  selectedDate = '' // ใช้้สำหรับแสดงรายละเอียดใต้ปฏิทิน

  maidCalendarBookingTotalHours = []
  disabledDates = []
  // disableDate = this.duringWork ? { weekdays: [1, 2, 3, 4, 5, 6, 7] } : null;

  bookingForm = {
    id: null, // for update booking only (unneccessary for new booking)
    // type: null,

    date: null,
    time: null,
    location: {
      description: '',
      main_text: '',
      place_id: '',
      secondary_text: '',
    },
    equipment: null, // 0 = มีอุปกรณ์, 75 = ไม่มีอุปกรณ์ แม่บ้านต้องเตรียมไป
    accommodation: null,
    duration: null,
    services: null,
    additional: [],
    method: 0, // 0 = Auto, 1 = Manual
    maid: null,
    description: '',
    status: '',
    payment: null,
    bookingCalendars: [],
    isChangeMaid: false,
    isChangeMaidBefore: false,
    currentMaid: null,
    deductPrice: 0,
  }
  sameDay = null
  totalPrice = 0
  deductPrice = 0
  isAgreePolicy = false
  isHistoryBooking = false
  isFetchingPrice = false
  minimumDuration = 2 // hr (fix me: move to constant file)
  isCanceled = false
  contentType = ''
  isMonth = null
  isFetchBooking = false
  isFetching = false
  isFetchingMaid = false
  finishPrepareData = true
  warningModalType = ''
  moveMonth = false
  isGetDeduct = false
  isMaidOutOfRadius = false
  prevRoute = {
    name: '',
  }
  temp_id = null
  isNotComplete = false
  dontShowCondoWarning = false
  condoWarningDisplayed = true

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from
    })
  }

  // ============ Calendar Attributes ============ //

  get todayHighlight() {
    return {
      key: 'today',
      // highlight: true,
      content: 'blue',
      dates: new Date(...this.$momentTZ().toArray()),
    }
  }

  get currentSelectDateHighlight() {
    let sameDay = this.disabledDates.filter((d) => {
      return (
        moment(d.start_datetime).format('YYYY-MM-DD') ==
        moment(this.bookingForm.date).format('YYYY-MM-DD')
      )
    })

    this.sameDay = sameDay
    if (sameDay.length > 0) {
      return null
    } else {
      return {
        key: 'selectedDay',
        bar: true,
        dates: this.selectedDate ? this.selectedDate : null,
      }
    }
  }

  get firstDot() {
    return {
      key: 'firstDot',
      dot: { class: 'custom-dot-gray' },
      dates: this.calendarTotalHours
        .filter((d) => {
          const isDisabledDate = isDateInTimeSlots(d.date, [
            ...this.disabledDates,
            ...this.baseDisabledHighlight,
          ])
          // console.log("isDisabledDate", d);
          return d.total_hours >= 2 && !isDisabledDate
        })
        .map((d) => this.$momentTZ(d.date).locale(this.$i18n.locale).format()),
    }
  }

  get secondDot() {
    return {
      key: 'secondDot',
      dot: { class: 'custom-dot-gray' },
      dates: this.calendarTotalHours
        .filter((d) => {
          const isDisabledDate = isDateInTimeSlots(d.date, [
            ...this.disabledDates,
            ...this.baseDisabledHighlight,
          ])
          return d.total_hours >= 4 && !isDisabledDate
        })
        .map((d) => this.$momentTZ(d.date).locale(this.$i18n.locale).format()),
    }
  }

  get thirdDot() {
    return {
      key: 'thirdDot',
      dot: { class: 'custom-dot-gray' },
      dates: this.calendarTotalHours
        .filter((d) => {
          const isDisabledDate = isDateInTimeSlots(d.date, [
            ...this.disabledDates,
            ...this.baseDisabledHighlight,
          ])
          return d.total_hours >= 6 && !isDisabledDate
        })
        .map((d) => this.$momentTZ(d.date).locale(this.$i18n.locale).format()),
    }
  }

  get disabledDatesHighlight() {
    // console.log("this.disabledDates", this.disabledDates);
    return this.disabledDates.map((d) => ({
      key: 'busy',
      highlight: 'gray',
      content: isToday(d.start_datetime) ? 'blue' : 'gray',
      dates: this.$momentTZ(d.start_datetime).locale(this.$i18n.locale).format(),
    }))
  }

  get maidCalendarAttrs() {
    return [
      this.todayHighlight,
      this.currentSelectDateHighlight,
      this.firstDot,
      this.secondDot,
      this.thirdDot,
      ...this.baseDisabledHighlight,
      ...this.disabledDatesHighlight,
    ]
  }

  get minDate() {
    const minDate = new Date()
    minDate.setDate(minDate.getDate() + 1)
    return minDate
  }

  get defaultCalendarAttrs() {
    return [this.todayHighlight, this.currentSelectDateHighlight, ...this.baseDisabledHighlight]
  }
  //  ============ Calendar Attributes (end) ============ //

  // ============ Options ============ //
  get durationOptions() {
    const minimumDuration = this.currentBookingOneTime.duration || this.minimumDuration
    const baseOptions = [...Constant.DURATION]
      .map((option) => ({
        ...option,
        text: `${option.value} ${this.$t('form_label.hrs')}`,
      }))
      .filter((option) => option.value >= minimumDuration)

    if (!this.bookingForm.time) return baseOptions
    const { hour, minute } = this.bookingForm.time
    const startDateTime = this.$momentTZ(this.bookingForm.date).hour(hour).minute(minute)
    return baseOptions.filter((option) => {
      // สร้าง end_datetime โดยคำนวนจาก option (option.value คือชั่วโมง)
      const endDateTime = this.$momentTZ(this.bookingForm.date)
        .hour(hour + option.value)
        .minute(minute)

      if (
        this.isMyBookingOneTime &&
        this.bookingForm.time &&
        this.$momentTZ(this.bookingForm.date)
          .hour(this.bookingForm.time.hour)
          .minute(this.bookingForm.time.minute)
          .isSame(moment(this.myBookingOneTime.start_datetime))
      ) {
        if (
          this.$momentTZ(this.bookingForm.date)
            .hour(hour + option.value)
            .minute(minute)
            .isSame(moment(this.myBookingOneTime.end_datetime))
        ) {
          return true
        }
      }

      if (this.bookingForm.method === 1) {
        if(this.bookingForm.maid) {
          // Manual Select
          // เช็คว่าเวลาที่สิ้นสุดการทำงาน อยู่ในช่วงที่แม่บ้านว่างมั้ย
            const isInAvailableTimeSlot = this.maidAvailableTimeSlots.some(
              ({ start_datetime, end_datetime }) => {
                return (
                  startDateTime >= this.$momentTZ(start_datetime) &&
                  endDateTime <= this.$momentTZ(end_datetime)
                )
              }
            )
            const endHour = this.bookingForm.time.hour + option.value
            // return (endHour <= 21 && minute === 0) || endHour < 21;
            return isInAvailableTimeSlot && ((endHour <= 21 && minute === 0) || endHour < 21)
        } else {
            const endHour = this.bookingForm.time.hour + option.value
            return (endHour <= 21 && minute === 0) || endHour < 21
        }
      } else {
        // this.timeOptions.filter((a) => {
        //   return (
        //     (a.hour + option.value <= 21 && a.minute === 0) ||
        //     a.hour + option.value <= 21
        //   );
        // });
        // Auto select
        //     const isAvailableForOTA = await this.inquiryMaidAvailableForOTA(
        //   this.bookingForm.date,
        //   end_datetime
        // );

        const endHour = this.bookingForm.time.hour + option.value

        console.log('endHour', endHour)
        return (endHour <= 21 && minute === 0) || endHour < 21
      }
    })
  }

  get duringWork() {
    // let an_2 = false;
    // const booking = this.bookingForm;
    // if (!booking.date) {
    //   return an_2;
    // } else {
    //   console.log(
    //     "ooooooooooooooooooooo",
    //     booking,
    //     // this.oneTime
    //     "new Date",
    //     moment(new Date()).toISOString(),
    //     "booking Date",
    //     moment(booking.date).add(booking.time.text, "hours").toISOString()
    //   );
    //   if (
    //     moment(booking.date).add(booking.time.text, "hours").toISOString() >
    //       moment(new Date()).toISOString() &&
    //     this.ohohoh
    //   ) {
    //     an_2 = true;
    //   } else {
    //     an_2 = false;
    //   }
    //   console.log("ann", an_2);
    //   return an_2;
    // }
    return false
  }
  get timeOptionsNothingSelect() {
    return this.timeOptions.length <= 0
  }

  get timeOptions() {
    // Manual select
    if (this.bookingForm.method === 1) {
      return Constant.TIME_OPTIONS.filter((t) => {
        // console.log("tttt",t);
        const startDateTime = this.$momentTZ(this.bookingForm.date).hour(t.hour).minute(t.minute)
        // เช็คว่า option ปัจจุบันอยู่ในช่วงที่แม่บ้านว่างไหม
        const isInAvailableTimeSlot = this.maidAvailableTimeSlots.some(
          ({ start_datetime, end_datetime }) => {
            return (
              startDateTime >= this.$momentTZ(start_datetime) &&
              startDateTime < this.$momentTZ(end_datetime) &&
              this.$momentTZ(end_datetime).diff(startDateTime, 'seconds') >= 60 * 60 * 2 // มากกว่า 2 ชั่วโมง
            )
          }
        )

        if (
          this.isMyBookingOneTime &&
          this.$momentTZ(this.bookingForm.date)
            .hour(t.hour)
            .minute(t.minute)
            .isSame(moment(this.myBookingOneTime.start_datetime))
        ) {
          return true
        }

        const { load } = this.$route.query
        if (typeof load === 'undefined' && this.bookingForm.duration && this.bookingForm.maid) {
          return (
            this.$momentTZ(this.bookingForm.date).hour(t.hour).minute(t.minute) >
              minimumBookingMoment() &&
            t.hour + this.bookingForm.duration.value <= 21 &&
            !(t.hour + this.bookingForm.duration.value == 21 && t.minute == 30) &&
            isInAvailableTimeSlot
          )
        }

        if (this.isFetchBooking && this.bookingForm.time && this.bookingForm.maid) {
          if (!this.upcomingInOneDay) {
            return startDateTime > minimumBookingMoment() && isInAvailableTimeSlot
          }
          return (
            startDateTime > minimumBookingMoment() &&
            isInAvailableTimeSlot &&
            t.hour >= this.bookingForm.time.hour
          )
        } 
        else if(!this.bookingForm.maid && this.stepOrder === 'dateFirst') {
          // return startDateTime > minimumBookingMoment() && isInAvailableTimeSlot
          return (
            this.$momentTZ(this.bookingForm.date).hour(t.hour).minute(t.minute) >
            minimumBookingMoment()
          )
        } 
        else if(this.bookingForm.maid && this.bookingForm.time && this.stepOrder === 'dateFirst') {
          return startDateTime > minimumBookingMoment() && isInAvailableTimeSlot
        } 
        else {
          // this.bookingForm.time = this.oneTime.time;
          // console.log(
          //   "this.bookingForm.time",
          //   this.bookingForm.time,
          //   this.oneTime.time
          // );
          return startDateTime > minimumBookingMoment() && isInAvailableTimeSlot
        }
      })
    } else {
      // Auto select

      const { load } = this.$route.query
      if (typeof load === 'undefined' && this.bookingForm.duration) {
        return Constant.TIME_OPTIONS.filter((t) => {
          return (
            this.$momentTZ(this.bookingForm.date).hour(t.hour).minute(t.minute) >
              minimumBookingMoment() &&
            t.hour + this.bookingForm.duration.value <= 21 &&
            !(t.hour + this.bookingForm.duration.value == 21 && t.minute == 30)
          )
        })
      } else {
        return Constant.TIME_OPTIONS.filter((t) => {
          return (
            this.$momentTZ(this.bookingForm.date).hour(t.hour).minute(t.minute) >
            minimumBookingMoment()
          )
        })
      }
    }
  }

  timeOptionsKey = 0
  @Watch('timeOptions')
  onTimeOptionsChange(){
    this.timeOptionsKey++
    console.log('timeOptions', this.timeOptions, this.bookingForm.time ? this.bookingForm.time.text : '')
  }

  get grandTotal() {
    if (this.isServiceCompleted || this.isCanceled)
      return this.currentBookingOneTime.payment_total_price
    if (!this.isBookingOneTime) {
      if (this.isFetchingPrice) return 0
      const diffPrice = this.totalPrice - this.deductPrice
      return diffPrice > 0 ? diffPrice : 0
    }
    const grandTotal = this.isFetchingPrice ? 0 : this.totalPrice
    return grandTotal > 0 ? grandTotal : 0
  }

  get canChangeMaid() {
    if (this.isBookingOneTime) {
      return Boolean(this.bookingForm.maid)
    } else {
      // maid didn't accpet when less than 24hr of appointment
      const isMaidDidntAccpect = this.upcomingInOneDay && !this.isApproved
      // console.log("this.oneTime.isChangeMaid", this.oneTime.isChangeMaid);
      return this.isBookingUnSuccessful || this.oneTime.isChangeMaid || isMaidDidntAccpect
    }
  }

  async chooseMaid() {
    // this.$root.$emit('bv::show::modal', 'warning-option-change-maid')
    if (!this.isBookingOneTime && this.isBookingUnSuccessful && !this.currentBookingOneTime.maid) {
      console.log('RESET MAID HIDDEN LIST')
      await this.$store.commit('maid/resetMaidHiddenList')
    }

    const route = this.$route
    let start_datetime, end_datetime
    if (this.bookingForm.date && this.bookingForm.duration) {
      start_datetime = this.bookingForm.date
      end_datetime = this.$momentTZ(this.bookingForm.date)
        .add(this.bookingForm.duration.value, 'hours')
        .toISOString()
    } else if(this.bookingForm.date && !this.bookingForm.duration && (this.stepOrder === '' || this.stepOrder === 'dateFirst')) {
      start_datetime = this.bookingForm.date
      end_datetime = this.bookingForm.date
    }
    await this.updateBookingOnetime(this.bookingForm)

    let query = {
      type: 'one-time',
      start_datetime: this.isBookingOneTime ? start_datetime : null,
      end_datetime: this.isBookingOneTime ? end_datetime : null,
      callback: route.path,
      from: route.query.from,
    }

    if (ENABLE_FILTER_MAID_BY_USER_LOCATION) {
      query = {
        ...query,
        location_latitude: get(this.bookingForm, 'location.latitude'),
        location_longitude: get(this.bookingForm, 'location.longitude'),
      }
    }

    if (this.isUserSelectBringEquipmentOption) {
      query = {
        ...query,
        bring_equipment: '1',
      }
    }

    if (this.bookingForm.services) {
      query = {
        ...query,
        services: this.bookingForm.services.value,
      }
    }

    if(this.bookingForm.duration) {
      query = {
        ...query,
        duration: this.bookingForm.duration.value
      }
    }

    localStorage.setItem('detailForm', JSON.stringify(this.bookingForm));
    localStorage.removeItem('bookingState');
    await this.updateBookingOnetime(this.bookingForm)

    this.$router.push({ name: 'SelectMaid', query })
  }

  chooseOptionMaid () {
    // if (this.bookingDetail === 'MyBooking' && this.stepOrder === '') {
    //   this.$root.$emit('bv::show::modal', 'warning-option-change-maid')
    //   return
    // } else {
    // }
    this.chooseMaid()
  }

  unselectMaid() {
    this.bookingForm.maid = null;
    this.bookingForm.bookingCalendars = [];
    // this.selectedTime = 'Start';
    // this.selectedDate = '';
    // this.bookingForm.date = null
    // this.bookingForm.time = null
    // this.bookingForm.duration = null;
    this.maidCalendarBookingTotalHours = [];
    this.disabledDates = []
    this.resetMaidCalendar();
  }

  // optionChangeMaid (type) {
  //   if (type === 'maid') {
  //     localStorage.setItem('stepOrder', 'hideStep');
  //     this.chooseMaid();
  //   } else if (type === 'date') {
  //     this.dateMode = true;
  //     this.stepOrder = 'dateFirst';
  //     this.switch = false;
  //     this.updateDropdown();
  //     this.unselectMaid();
  //     this.$root.$emit('bv::hide::modal', 'warning-reset-time-slots')
  //     this.$root.$emit('bv::hide::modal', 'warning-option-change-maid')
  //     return
  //   }
  // }

  checkTimeCurrent() {
      const current_date = localStorage.getItem("currentDate") || new Date();
      const currentTime = new Date().getTime();
      const dateThreshold = 1000 * 60 * 60; // 1 hour in milliseconds
      const wrong_current_date = current_date && (Math.abs(new Date(current_date).getTime() - currentTime) > dateThreshold);
      this.dateTimeTimezone = this.getDateTimeTimezone()
      return wrong_current_date && !this.isAdmin
  }

  get isAdmin() {
    let adminToken = window.localStorage.getItem('admin_token')
    return this.isLoggedIn && adminToken && adminToken !== 'null'
  }

  async bookNow() {
    if(!this.canBooking && !this.isFetching){
      this.alertNotComplete()
      return
    }
    if(this.checkTimeCurrent()){
      this.$root.$emit('bv::show::modal', 'warning-invalid-datetime')
      return
    }
    this.temp_id = uuidv4()
    await this.updateBookingOnetime(this.bookingForm)

    const start_datetime = this.$momentTZ(this.bookingForm.date)
      .hour(this.bookingForm.time.hour)
      .minute(this.bookingForm.time.minute)
      .toISOString()
    const end_datetime = this.$momentTZ(start_datetime)
      .add(this.bookingForm.duration.value, 'hours')
      .toISOString()

    const calendar = [
      {
        number: 1,
        start_datetime,
        end_datetime,
      },
    ]
    this.setBookingReview({
      temp_id: this.temp_id,
      ...this.bookingForm,
      amount: this.grandTotal,
      type: 'one-time',
      calendar,
      isUpdateBooking: false,
      calendarPassed: [],
    })

    const isAvailableForOTA = await this.inquiryMaidAvailableForOTA({
      ...calendar[0],
      bring_equipment: this.isUserSelectBringEquipmentOption,
      // services:this.bookingForm.services!=="CLEANING"?"DRAPERY":""

      // location_latitude: get(this.bookingForm, 'location.latitude'),
      // location_longitude: get(this.bookingForm, 'location.longitude')
    })
    // console.log('this.oneTime', this.oneTime)
    if (!isAvailableForOTA) return

    this.$router.push({ 
      name: 'BookingReview',        
      query: {
          temp_id: this.temp_id,
      }, 
    })
  }

  alertNotComplete() {
    this.isNotComplete = true;
    const fieldsToCheck = [
      { id: 'not-complete-location', condition: !this.bookingForm.location.main_text },
      { id: 'not-complete-equipment', condition: !this.bookingForm.equipment },
      { id: 'not-complete-services', condition: !this.bookingForm.services },
      { id: 'not-complete-maid', condition: this.bookingForm.method == 1 && !this.bookingForm.maid },
      { id: 'not-complete-date', condition: !this.bookingForm.date },
      { id: 'not-complete-time', condition: !this.bookingForm.time },
      { id: 'not-complete-accommodation', condition: !this.bookingForm.accommodation },
      { id: 'not-complete-duration', condition: !this.bookingForm.duration },
      { id: 'not-complete-policy', condition: !this.isAgreePolicy }
    ];

    let scrollTo = 0;

    setTimeout(() => {
      for (const { id, condition } of fieldsToCheck) {
        if (condition) {
          scrollTo = document.getElementById(id).getBoundingClientRect().top + window.scrollY;
          break;
        }
      }

      console.log('scrollTo', scrollTo);
      window.scrollTo({
        top: scrollTo - 100,
        behavior: 'smooth'
      });
    }, 100);
  }


  // Duplicate with above
  continueBookNow() {
    this.$root.$emit('bv::hide::modal', 'warning-maid-available-modal')
    if (this.warningModalType === 'notice_maid_availability') {
      this.$router.push({ 
        name: 'BookingReview',
        query: {
            temp_id: this.temp_id,
        },
      })
    }
    this.warningModalType = ''
  }

  async inquiryMaidAvailableForOTA({
    start_datetime,
    end_datetime,
    bring_equipment,
    // services
  }) {
    this.isFetching = true
    if (this.bookingForm.method == 1) {
      return true // OTM
    }

    try {
      let body = {
        start_datetime,
        end_datetime,
        bring_equipment,
        services: this.bookingForm.services.value === 'CLEANING' ? 'CLEANING' : 'DRAPERY',
        // location_latitude,
        // location_longitude
      }
      if (!bring_equipment) {
        delete body.bring_equipment
      }
      console.log('bookingForm Service', body)
      const result = await ApiClient.maidAvailable(body)
      // console.log('result.data', result.data)
      const { available_value, inventory } = result.data
      this.isFetching = false
      if (available_value <= 0) {
        // Show Modal High demand
        this.warningModalType = 'maid_high_demand'
        this.$root.$emit('bv::show::modal', 'warning-maid-available-modal')
        return false
      } else if (available_value === 1) {
        // Show modal notice
        this.warningModalType = 'notice_maid_availability'
        this.$root.$emit('bv::show::modal', 'warning-maid-available-modal')
        return false
      }
    } catch (e) {
      console.log(e)
    }
    return true
  }

  onSelectedDate(day) {
    // if (!this.canSelectDate) {
    //   this.$root.$emit("bv::show::modal", "warning-cannotselectday");
    //   setTimeout(() => {
    //     this.$root.$emit("bv::hide::modal", "warning-cannotselectday");
    //   }, 3000);
    // } else {
    console.log('day', this.upcomingInOneDay)
    // Case update booking
    // const offset =
    //   -(new Date().getTimezoneOffset() / 60) < 0 ? 0 : -(new Date().getTimezoneOffset() / 60)
    if (this.upcomingInOneDay && this.isApproved && !this.isBookingOneTime) {
      if (!this.isBookingUnSuccessful) return
    }

    const selectedDate = moment.tz(day.id + ' 00:00:00', 'YYYY-MM-DD HH:mm:ss', 'UTC').toISOString() //new Date(day.dateTime).toISOString()

    // Check is in disable ()
    const isDisabledDate = this.disabledDatesHighlight
      .concat(this.baseDisabledHighlight)
      .some(({ dates }) => {
        const today = isToday(moment(day.dateTime).toISOString())

        return moment(day.id).isSame(moment(dates), 'date') || today
      })
    console.log('isDisabledDate', isDisabledDate)
    if (isDisabledDate) return
    if (this.isReview) return

    if (isToday(selectedDate) || moment(selectedDate) < moment()) {
      // console.log("today");
      return
    }
    // const noTimeOptionsForTomorrow = moment(day.dateTime).hour() > 19
    // if (noTimeOptionsForTomorrow) {
    //   return
    // }
    this.selectedDate = selectedDate
    this.setCurrentSelectedOnCalendar(selectedDate)
    this.bookingForm.date = selectedDate // set date into booking form
    // if (this.bookingForm.time && this.stepOrder === 'dateFirst') {
    //   this.timeOptions.includes(this.bookingForm.time)
    //     ? null
    //     : this.timeOptions[0]
    //     ? null
    //     : null
    // }
    
    if (this.bookingForm.time && this.stepOrder === 'dateFirst') {
      const matchingTime = this.timeOptions.find(
        (t) => t.text === this.bookingForm.time.text
      );

      if (!matchingTime) {
        this.bookingForm.time = this.timeOptions[0];
      } 
      // else {
      //   this.bookingForm.time = null;
      // }
    }
  }

  get calendarValue() {
    if (!this.bookingForm.date) return ``
    // if (String(this.isMonth) !== moment(this.bookingForm.date).format("MM"))
    // return ``;
    // if (this.sameDay.length > 0) {
    //   return ``;
    // }
    const template = this.$i18n.locale === 'th' ? 'ddd DD MMM YYYY' : 'ddd MMM DD, YYYY'
    return this.$momentTZ(this.bookingForm.date).locale(this.$i18n.locale).format(template)
  }

  get recommendDurationText() {
    if (!this.bookingForm.accommodation || this.bookingForm.accommodation.value == 'OTHERS')
      return ''
    const recommendDuration = {
      CONDO_1_BR: `3 - 4 ${this.$t('alias.hour')}`,
      CONDO_2_BR: `4 - 5 ${this.$t('alias.hour')}`,
      CONDO_3_BR: `5 - 6 ${this.$t('alias.hour')}`,
      CONDO_4_BR: `6 - 8 ${this.$t('alias.hour')}`,
      HOUSE_1_STORY: `3 - 4 ${this.$t('alias.hour')}`,
      HOUSE_2_STORIES: `4 - 6 ${this.$t('alias.hour')}`,
      HOUSE_3_STORIES: `6 - 8 ${this.$t('alias.hour')}`,
      OFFICE: `3 - 4 ${this.$t('alias.hour')}`,
    }
    return `${this.$t('form_label.recommend_duration')} ${
      recommendDuration[this.bookingForm.accommodation.value]
    }`
  }

  get recommendDurationTextClass() {
    if (!this.bookingForm.accommodation || !this.bookingForm.duration ) return false
    const num = this.bookingForm.duration.value
    switch (this.bookingForm.accommodation.value) {
      case 'CONDO_1_BR':
        return num >= 3
      case 'CONDO_2_BR':
        return num >= 4
      case 'CONDO_3_BR':
        return num >= 5
      case 'CONDO_4_BR':
        return num >= 6
      case 'HOUSE_1_STORY':
        return num >= 3
      case 'HOUSE_2_STORIES':
        return num >= 4
      case 'HOUSE_3_STORIES':
        return num >= 6
      case 'OFFICE':
        return num >= 3
      default:
        return false
    }
  }

  get showCondoWarningCheck() {
    if(!this.isBookingOneTime){
      if(!this.isUpdateDate && !this.isUpdateAccommodation) return false
    }

    if (!this.bookingForm.accommodation || !this.bookingForm.time || !this.bookingForm.time.hour ) return false
    if (!['CONDO_1_BR', 'CONDO_2_BR', 'CONDO_3_BR', 'CONDO_4_BR'].includes(this.bookingForm.accommodation.value)) return false
    if (this.bookingForm.time.hour < 16 && this.bookingForm.time.hour > 7 ) return false
    if (this.bookingForm.maid && this.bookingForm.time) return false
    return true
  }

  @Watch('showCondoWarningCheck')
  onShowCondoWarningCheckChange() {
    if (this.showCondoWarningCheck && !this.condoWarningDisplayed && !this.accommodation_confirmed) {
      // let prevTimeCondoWarning = localStorage.getItem('timeCondoWarning')
      // if (prevTimeCondoWarning) {
      //   let timeCondoWarning = new Date(prevTimeCondoWarning)
      //   let currentTime = new Date()
      //   let diff = currentTime - timeCondoWarning
      //   if (diff < 1000 * 60 * 5) {
      //     return
      //   }
      // }

      this.$root.$emit('bv::show::modal', 'confirm-condo-modal')

      // let newTimeCondoWarning = new Date()
      // localStorage.setItem('timeCondoWarning', newTimeCondoWarning)
    } 
  }

  get shouldShowCalendarDetail() {
    return (
      !!this.selectedDate &&
      !!this.bookingForm.maid &&
      this.maidAvailableTimeSlots.length > 0 &&
      this.bookingForm.method === 1
    )
  }

  /**
   * คืนค่า slot เวลาที่เมดว่าง ณ วันที่กำลังเลือกอยู่
   */
  get maidCalendarAvailableTimes() {
    if (!this.selectedDate) return []
    return this.maidAvailableTimeSlots
  }

  get isUserLocationOutOfMaidRadius() {
    return this.requireCheckMaidRadius && this.isMaidOutOfRadius
  }

  get canBooking() {
    const isSelectAccommodation = this.bookingForm.accommodation !== null
    const isSelectServices = this.bookingForm.services !== null
    const isSelectDuration = this.bookingForm.duration !== null

    console.log(
      '🚀 ----------------------------------------------------------------------------------------------------------------------------'
    )
    console.log(
      '🚀 ~ file: BookOneTime.vue ~ line 1143 ~ BookOneTime ~ getcanBooking ~ this.isSelectAvailableDate',
      console.log(this.bookingForm.location)
    )
    // console.log(
    //   this.isAgreePolicy,
    //   !!this.bookingForm.location.main_text,
    //   this.isSelectAvailableDate,
    //   isSelectServices,
    //   isSelectAccommodation,
    //   isSelectDuration,
    //   this.bookingForm.date,
    //   this.bookingForm.time,
    //   !this.isFetching,
    //   this.isMaidPublished,
    //   !this.isUserLocationOutOfMaidRadius,
    //   !this.hasEquipmentError,
    //   this.isSelectedServiceOptionValidWithMaid,
    //   !this.isUserLocationOutOfMaidRadius
    // )
    console.log(
      'update 🚀 ----------------------------------------------------------------------------------------------------------------------------'
    )

    return (
      this.isAgreePolicy &&
      !!this.bookingForm.location.main_text &&
      this.isSelectAvailableDate &&
      isSelectServices &&
      isSelectAccommodation &&
      isSelectDuration &&
      this.bookingForm.date &&
      this.bookingForm.time &&
      !this.isFetching &&
      this.isMaidPublished &&
      !this.isUserLocationOutOfMaidRadius &&
      !this.hasEquipmentError &&
      this.isSelectedServiceOptionValidWithMaid &&
      !this.isUserLocationOutOfMaidRadius &&
      !this.isaAllergicMaid
    )
  }

  get isSelectAvailableDate() {
    if (
      this.isMyBookingOneTime &&
      this.bookingForm.time &&
      this.$momentTZ(this.bookingForm.date)
        .hour(this.bookingForm.time.hour)
        .minute(this.bookingForm.time.minute)
        .isSame(moment(this.myBookingOneTime.start_datetime)) &&
      (this.bookingForm.method === 0 ||
        (this.myBookingOneTime.booking.maid &&
          this.bookingForm.maid &&
          this.myBookingOneTime.booking.maid.id === this.bookingForm.maid.id))
    ) {
      return true
    }
    if (this.bookingForm.method === 0) {
      if (new Date(this.bookingForm.date) < new Date()) {
        return false
      } else {
        return true
      }
    }
    if (!this.bookingForm.date || !this.bookingForm.duration) {
      return false
    }
    const startDatetime_2 = this.$momentTZ(this.bookingForm.date)
    const startDatetime = this.$momentTZ(startDatetime_2)
      .hour(this.bookingForm.time.hour)
      .minute(this.bookingForm.time.minute)
    const endDateTime = this.$momentTZ(this.bookingForm.date).add(
      this.bookingForm.duration.value,
      'hours'
    )
    return this.maidAvailableTimeSlots.some((d) => {
      // console.log("d", d);
      // console.log(this.maidAvailableTimeSlots);
      const maidStartDateTime = this.$momentTZ(d.start_datetime)
      const maidEndDateTime = this.$momentTZ(d.end_datetime)
      // console.log(
      //   `startDatetime :: ${startDatetime},maidStartDateTime :: ${maidStartDateTime}`
      // );
      // console.log(
      //   `maidStartDateTime :: ${maidStartDateTime},maidEndDateTime :: ${maidEndDateTime}`
      // );
      // console.log(
      //   "alllll",
      //   startDatetime >= maidStartDateTime && endDateTime <= maidEndDateTime
      // );
      return startDatetime >= maidStartDateTime && endDateTime <= maidEndDateTime
    })
  }

  get route() {
    return this.$route.name
  }

  get currentRoute() {
    return this.$router.currentRoute
  }

  get isBookingOneTime() {
    return this.route === 'BookingOneTime'
  }

  get durationInOption() {
    let x = false
    this.durationOptions.forEach((a) => {
      if (a.value == this.bookingForm.duration.value) {
        x = true
      }
    })
    return x
  }

  @Watch('bookingForm.time')
  onTimeChanged(time, oldVal) {
    console.log('bookingForm.time', time , oldVal)
    if (!time) return
    const { hour, minute } = time
    const startDate = this.$momentTZ(this.bookingForm.date)
      .hour(hour)
      .minute(minute)
      .second(0)
      .millisecond(0)
    this.bookingForm.date = startDate.toISOString()

    // Reset duration when user change time option
    if (Boolean(oldVal) || this.bookingForm.duration) {
      // console.log("compute", this.durationInOption);
      if (!this.durationInOption) {
        this.bookingForm.duration = this.durationOptions[this.durationOptions.length - 1]
      }

      // this.bookingForm.duration = null

      // this.$refs.durationSelect.typeAheadPointer = -1
      // this.$refs.durationSelect.selectedValue = null
    }

    // Pre-fill duration
    this.preFillDuration()
  }

  preFillDuration() {
    if (this.isBookingOneTime || this.isFetching) return
    const options = this.durationOptions
    if (options.length === 0) return
    const duration = options[0]
    this.bookingForm.duration = { ...duration }
  }

  @Watch('bookingForm.equipment')
  async onEquipmentChange() {
    await this.calcuatePrice()
  }

  @Watch('bookingForm.duration')
  async calcuatePrice() {
    if (isEmpty(this.bookingForm.duration)) return
    try {
      this.isFetchingPrice = true

      const result = await ApiClient.getBookingPrice({
        duration: get(this.bookingForm, 'duration.value'),
        multi_times: 1,
        type:
          get(this.bookingForm, 'method', 0) === 0
            ? Constant.ONE_TIME_AUTO_SELECT
            : Constant.ONE_TIME_MANUAL_SELECT,
        bring_equipment: this.isUserSelectBringEquipmentOption,
      })

      // const result = await ApiClient.getPriceOneTime(duration.value)
      this.totalPrice = get(result, 'data.total_price')
    } catch (e) {
      console.log(e)
    } finally {
      this.isFetchingPrice = false
    }
  }

  async getDeductPrice(hour) {
    try {
      this.isFetchingPrice = true
      const result = await ApiClient.getBookingPrice({
        duration: hour,
        multi_times: 1,
        type:
          get(this.bookingForm, 'method', 0) === 0
            ? Constant.ONE_TIME_AUTO_SELECT
            : Constant.ONE_TIME_MANUAL_SELECT,
        bring_equipment: this.isUserSelectBringEquipmentOption,
      })

      // const resultOld = await ApiClient.getPriceOneTime(hour)
      console.log('deduct price', result)
      this.bookingForm.deductPrice = get(result, 'data.total_price')
      this.deductPrice = get(result, 'data.total_price')
    } catch (error) {
      console.log(error.response)
    } finally {
      this.isFetchingPrice = false
    }
  }

  @Watch('bookingForm.method')
  onMethodChanged(newVal, oldval) {
    console.log("newVal 1", newVal, oldval);
    if (newVal === 0) {
      console.log('this.disabledDates', this.disabledDates)
      this.logDisableDate = this.disabledDates
      this.disabledDates = []
    } else if (newVal === 1 && this.disabledDates.length <= 0 && this.logDisableDate && this.logDisableDate[0]) {
      this.disabledDates = this.logDisableDate
    }
    // if (this.isFetching) return
    if (!this.isBookingOneTime && !this.isBookingUnSuccessful) {
      console.error('Change by inspect element')
      const { load } = this.$route.query
      if (typeof load === 'undefined') {
        this.fetchBookingDetail()
      }

      // this.$router.push({ name: 'MyBooking' })
      // return;
    }
    const form = clone(this.bookingForm)
    this.updateBookingOnetime({ ...form, method: newVal })
  }

  requireAuthIfNotLoggedIn() {
    if (!this.isLoggedIn || !isAuthenticated()) {
      this.$root.$emit('bv::show::modal', 'require-auth-modal')
      return false
    }
    return true
  }

  async getPaymentHistory() {
    // console.log("getpayment");
    try {
      const result = await ApiClient.paymentHistory({
        page: 1,
        limit: 20,
      })
      const { page_count, payment } = result.data
      // console.log("paymrnt",payment);
      this.payments = payment[0]
      // console.log("paymrnt", this.payments);
      // console.log("this.$i18n.locale", this.$i18n.locale);
      const dateFormat = this.$i18n.locale === 'th' ? `DD MMM YYYY, H:mm` : `MMM DD, YYYY, H:mm`
      // let {time_pay, time_now} = null
      let time_pay = new Date(payment[0].create_datetime)
      let time_now = new Date()
      let time_can_pay = new Date(time_pay.getTime() + 60 * 60 * 24 * 1000)

      this.time_can_pay = this.$momentTZ(time_can_pay).locale(this.$i18n.locale).format(dateFormat)
      this.$i18n.locale === 'th' ? this.time_can_pay + ' น.' : null
      if (time_now <= time_can_pay) {
        // ปิด function สำหรับทำรายการ 24 hr
        // this.canSelectDate = false
      } else {
        null
      }
      // console.log("can select", this.canSelectDate);
    } catch (e) {
      console.log(e.response)
    }
  }

  async getMaidById(id) {
    try {
      this.isFetchingMaid = true
      const result = await ApiClient.maidDetail(id)
      return result.data
    } catch (error) {
      console.log(error.response)
    } finally {
      this.isFetchingMaid = false
    }
  }

  async mounted() {
    this.$store.dispatch('showFullContentLoader', true)
    this.resetMaidCalendar()

    if (!this.$route.query.load) {
      if (this.prevRoute.name !== 'SelectMaid' && this.prevRoute.name !== 'BookingReview') {
        localStorage.removeItem('stepOrder');
      }
    } else {
      console.log('Load query parameter is present:', this.$route.query.load);
    }

    // this.getuserCurrentLocation()

    // const startDate_2= new Date(date)+1
    // if (isUATEnv) {
    //   if (localStorage.oneTime && !this.oneTime.maid) {
    //     this.oneTime = JSON.parse(localStorage.oneTime);
    //   }
    // }
    const { date, maid } = this.oneTime
    let isSelectMaidAfterChangeOTM = false

    if (date) {
      const startDate = new Date(date)
      await this.$refs.calendar.focusDate(startDate)
      this.setCurrentSelectedOnCalendar(startDate.toString())
    }

    if (maid && date) {
      const { id } = maid
      const startDate = new Date(date)
      console.log('getMaidBookingTotal 1', startDate.getMonth() + 1, startDate.getFullYear())
      await this.getMaidBookingTotal({
        id,
        month: startDate.getMonth() + 1,
        year: startDate.getFullYear(),
      })
      this.setCurrentSelectedOnCalendar(startDate.toString())
    }

    if (this.isLoggedIn) {
      this.getPaymentHistory()
    }

    // Get All maid detail
    let maidDetail = {}
    if (get(maid, 'id')) {
      maidDetail = await this.getMaidById(maid.id)
    }

    const maidFullDetail = { ...maid, ...maidDetail }

    if (this.isBookingOneTime) {
      Object.assign(this.bookingForm, {
        ...this.oneTime,
        maid: !isEmpty(maidFullDetail) ? maidFullDetail : null,
      })

      if (this.oneTime.maid !== null) {
        let payload = {
          id: this.oneTime.maid.id,
          month: this.minDate.getMonth() + 1,
          year: this.minDate.getFullYear(),
        }
        const maidCalendar = this.isLoggedIn
          ? await this.fetchMaidCalendarAllMonth(payload)
          : await this.fetchMaidCalendarAllMonthPublic(payload)
        this.disabledDates = getDisabledDateFromTimeSlot(maidCalendar)
        // if (isUATEnv) {
        //   if (this.oneTime.method === 1) {
        //     this.bookingForm.method = 1;
        //   } else this.bookingForm.method = 0;
        // } else {
        this.bookingForm.method = 1

        console.log('onetimeeeee',this.oneTime.maid)

        if (!localStorage.getItem("bookingState")) {
          this.bookingForm.time = this.oneTime.maid.selected_time;
          this.bookingForm.date = this.oneTime.maid.selected_date;
          this.selectedDate = this.oneTime.maid.selected_date;
          this.setCurrentSelectedOnCalendar(this.oneTime.maid.selected_date)
        } else {
          const bookingState = JSON.parse(localStorage.bookingState)

          this.bookingForm.time = this.oneTime.maid.selected_time ? this.oneTime.maid.selected_time : bookingState.time;
          this.bookingForm.date = this.oneTime.maid.selected_date ? this.oneTime.maid.selected_date : bookingState.date;
          this.selectedDate = this.oneTime.maid.selected_date ? this.oneTime.maid.selected_date : bookingState.date;

          if(this.oneTime.maid.selected_date) {
            this.setCurrentSelectedOnCalendar(this.oneTime.maid.selected_date)
          } else {
            this.setCurrentSelectedOnCalendar(bookingState.date)
          }
        }

        // this.timeOptions = Constant.TIME_OPTIONS.filter((t) => {
        //   return (
        //     this.$momentTZ(this.timeOptions).hour(t.hour).minute(t.minute)
        //   )
        // })
        // console.log('loadddddd', this.$route.query.load)
        // const storedStepOrder = localStorage.getItem('stepOrder');
        // if ((!storedStepOrder && this.$route.query.load !== false) || this.$route.query.type !== 'one-time') {
        //   this.unselectMaid();
        // }
      } else {
          this.selectedTime = 'Start';
          this.selectedDate = '';
          this.bookingForm.date = null
          this.bookingForm.time = null
          // this.unselectMaid()
      }
      // await  this.fetchMaidCalendarAllMonthPublic({id:this.oneTime.maid.id,month:new Date().getMonth()+1,year:new Date().getFullYear()})
      // console.log("disable",this.disabledDates);
      // this.disabledDates = getDisabledDateFromTimeSlot(true)
      //  setTimeout(()=>{
      //    this.fetchMaidCalendarAllMonthPublic({id:this.oneTime.maid.id,month:12,year:2020})
      //  },5000)
      // this.setCurrentSelectedOnCalendar(startDate_2.toString())

      // this.bookingForm.date = new Date()
    } else if (this.oneTime.isChangeMaid && this.oneTime.id && this.bookingForm.method === 1) {
      Object.assign(this.bookingForm, {
        ...this.oneTime,
        maid: !isEmpty(maidFullDetail) ? maidFullDetail : null,
      })
      this.oneTime.maid && this.fetchMaidDetail(this.oneTime.maid.id)
      console.log('this.bookingForm', this.bookingForm)
      console.log('this.oneTime', this.bookingForm)
      let end_datetime = null
      if (this.oneTime.maid !== null) {
        this.setMaidID(this.oneTime.maid.id)
        end_datetime = moment(this.bookingForm.date)
          .add(this.bookingForm.duration.value, 'hours')
          .toISOString()
      }

      const isAvailableForOTA = await this.inquiryMaidAvailableForOTA(
        this.bookingForm.date,
        end_datetime
        // null,
        // this.bookingForm.services!=="CLEANING"?"DRAPERY":""
      )
      // console.log("isAvailableForOTA", isAvailableForOTA);

      await this.$store.dispatch('booking/initBookingOneTime')
      Object.assign(this.bookingForm, {
        ...this.oneTime,
        maid: !isEmpty(maidFullDetail) ? maidFullDetail : null,
      })
      await this.getDeductPrice(this.currentBookingOneTime.duration)
      console.log('eeee : test replace value', this.bookingForm)

      this.deductPrice = this.oneTime.deductPrice
    } else {
      const router = this.$store.state.router
      const enterByRefresh = router.from.name === null

      console.log('enterByRefresh', enterByRefresh)

      const { load } = this.$route.query
      if (typeof load === 'undefined') {
        // console.log("fetch");
        await this.fetchBookingDetail(true)

        // console.log("this.oneTime", this.oneTime);
      }
      isSelectMaidAfterChangeOTM = true
      this.ohohoh = isSelectMaidAfterChangeOTM
    }

    if (this.oneTime.maid !== null && isSelectMaidAfterChangeOTM) {
      // Prevent set maid to null after fetchBookingDetail
      if (isEmpty(this.bookingForm.maid)) {
        Object.assign(this.bookingForm, {
          ...this.oneTime,
          maid: !isEmpty(maidFullDetail) ? maidFullDetail : null,
        })
      }
      console.log('this.bookingForm.duration', this.bookingForm.duration)
      if (this.bookingForm.duration) await this.getDeductPrice(this.bookingForm.duration.value)
    }

    if (this.bookingForm.maid !== null) {
      let isPublish = await ApiClient.maidDetail(this.bookingForm.maid.id)
      if (!(maid && date) && this.disabledDates.length == 0) {
        let payload = {
          id: this.oneTime.maid.id,
          month: this.minDate.getMonth() + 1,
          year: this.minDate.getFullYear(),
        }
        const maidCalendar = this.isLoggedIn
          ? await this.fetchMaidCalendarAllMonth(payload)
          : await this.fetchMaidCalendarAllMonthPublic(payload)
        this.disabledDates = getDisabledDateFromTimeSlot(maidCalendar)
      }
      this.publish_status = isPublish.data.status
      // console.log("this.publish_status", this.publish_status);
      this.bookingForm.method = 1
    }

    console.log('this.prevRoute', this.prevRoute)
    console.log('❌❌❌❌❌ this.bookingForm', this.bookingForm)
    console.log('this.bookingReview', this.bookingReview)
    const storedStepOrder = localStorage.getItem('stepOrder');

    if (this.isMyBookingOneTime && this.prevRoute.name == 'BookingReview') {
      this.bookingForm.additional = this.bookingReview.additional
      this.bookingForm.description = this.bookingReview.description
      this.bookingForm.location = this.bookingReview.location
      console.log('this.bookingReview 2', this.bookingReview)
    }
    if (this.prevRoute.name == 'BookingReview') {
      localStorage.isFromBookingReview = 'booking-review'

      const bookingState = JSON.parse(localStorage.bookingState);
      if (this.bookingForm.maid === null) {
        this.bookingForm = {...bookingState}
        this.selectedDate = bookingState.date;
      }
      this.bookingForm.date = bookingState.date;
      this.selectedDate = bookingState.date;
    } else {
      localStorage.isFromBookingReview = null
    }
    const storedDetail = JSON.parse(localStorage.detailForm);
    if (this.prevRoute.name == 'SelectMaid') {
      localStorage.isFromSelectMaid = 'select-maid'

      if (!storedStepOrder || storedStepOrder === 'maidFirst') {
          this.bookingForm.date = storedDetail.date;
          this.bookingForm.time = storedDetail.time;
          this.selectedDate = storedDetail.date;
          this.bookingForm.duration = storedDetail.duration;
          this.setCurrentSelectedOnCalendar(storedDetail.date)
        }
      else if (storedStepOrder === 'dateFirst') {
        if (this.oneTime.maid !== null) {
          this.bookingForm.time = this.oneTime.maid.selected_time;
          this.bookingForm.date = this.oneTime.maid.selected_date;
          this.selectedDate = moment.utc(this.oneTime.maid.selected_date).local().format('YYYY-MM-DD');
          this.setCurrentSelectedOnCalendar(this.oneTime.maid.selected_date)
        } else {
          this.bookingForm.time = storedDetail.time;
          this.bookingForm.date = storedDetail.date;
          this.selectedDate = storedDetail.date;
          this.setCurrentSelectedOnCalendar(storedDetail.date)
        }
          this.bookingForm.duration = storedDetail.duration;
      }
    } else {
      localStorage.isFromSelectMaid = null
    }

    this.condoWarningDisplayed = true
    if(!this.showCondoWarningCheck){
      this.condoWarningDisplayed = false
    }

    this.$store.dispatch('showFullContentLoader', false)
    this.$nextTick(() => {
      if (this.$refs.address === undefined) {
        window.location.reload()
      }
    })
    // this.bookingForm.method = 1
    console.log("bookingForm",this.bookingForm)
    if (storedStepOrder) {
      this.stepOrder = storedStepOrder;
      this.switch = false;
      this.updateDropdown();
    } 
    // else {
    //   // this.stepOrder = ''
    //   // localStorage.setItem('stepOrder', this.stepOrder);
    //   // this.selectedTime = 'Start';
    //   // this.selectedDate = '';
    //   // this.bookingForm.date = null
    //   // this.bookingForm.time = null
    //   // this.unselectMaid()
    // }

    // my booking case
    if (this.$route.query.type === 'one-time') {
      // this.stepOrder = 'maidFirst';
      this.bookingForm.time = storedDetail.time;
      this.bookingForm.date = storedDetail.date;
      this.selectedDate = storedDetail.date;
      this.setCurrentSelectedOnCalendar(storedDetail.date)
      this.bookingForm.method = 1
      this.switch = false;
      this.updateDropdown();
    } else if (this.$route.query.callback === 'MyBooking' && this.$route.query.tab === 'upcoming') {
      this.stepOrder = 'hideStep';
      this.updateDropdown();
    }
  }

  @Watch('route')
  async onRouteChanged(newVal) {
    // กดเปลี่ยนจาก My Booking OT to Booking OT
    if (newVal === 'BookingOneTime') {
      this.bookingForm = {
        id: null,
        date: null,
        time: null,
        location: {
          description: '',
          main_text: '',
          place_id: '',
          secondary_text: '',
        },
        equipment: null,
        accommodation: null,
        location_additional_details: null,
        duration: null,
        services: null,
        additional: [],
        method: 0,
        maid: null,
        description: '',
        status: '',
        payment: null,
        bookingCalendars: [],
        isChangeMaid: false,
        isChangeMaidBefore: false,
        currentMaid: null,
        deductPrice: 0,
      }
      this.selectedTime = 'Start'
      this.selectedDate = ''
      this.stepOrder = ''
      this.dropdownText = this.$t('pages.booking.switch_step')
      this.dropdownVariant = 'primary'
      this.selected = false
      this.maidCalendarBookingTotalHours = []
      this.disabledDates = []
      this.totalPrice = 0
      this.deductPrice = 0
      this.isAgreePolicy = false
      this.isHistoryBooking = false
      this.minimumDuration = 2 // hr
      this.isCanceled = false
      this.contentType = ''
      this.$store.commit('booking/resetCurrentBookingOneTime')
      await this.resetMaidCalendar()

      console.log('----- set new maid -----')
      const maidDetail = JSON.parse(window.sessionStorage.getItem('maid_detail'))
      if (maidDetail) {
        this.bookingForm.method = 1
        this.bookingForm.maid = maidDetail
        this.updateBookingOnetime(this.bookingForm)
        window.sessionStorage.removeItem('maid_detail')
      }
    } else {
      // this.bookingForm.method = 1
      this.fetchBookingDetail()
    }
    this.$root.$emit('bv::hide::modal', 'confirm-condo-modal')
  }

  @Watch('bookingForm.maid', { deep: true })
  async onMaidChanged(newVal) {
    console.log('onMaidChange', newVal)
    if (!this.isLoggedIn || !this.bookingForm.maid) return
    const { date } = this.oneTime
    const now = date ? new Date(date) : this.minDate
    console.log('getMaidBookingTotal 2', now.getMonth() + 1, now.getFullYear())
    await this.getMaidBookingTotal({
      id: this.bookingForm.maid.id,
      month: now.getMonth() + 1,
      year: now.getFullYear(),
      isMe: true,
    })
    if (this.bookingForm.time && !this.timeOptions.map((timeSlot) => timeSlot.text).includes(this.bookingForm.time.text)) {
      if (this.timeOptions.length > 0) {
        this.bookingForm.time = this.timeOptions[0]
      } 
      
      if (this.oneTime.maid !== null && (this.stepOrder === '' || this.stepOrder === 'dateFirst')) {
        this.bookingForm.time = this.oneTime.maid.selected_time;
      }
    }
    // this.bookingForm.method = 1
    console.log('MAID CHANED -> checkUserLocationWithMaidRadius', this.bookingForm.method)
    await this.checkUserLocationWithMaidRadius()
  }

  @Watch('isLoggedIn')
  onIsLoggedInChanged() {
    if (this.isLoggedIn) {
      this.$root.$emit('bv::hide::modal', 'require-auth-modal')
      if (this.callBackPathAfterWelcomePage && !this.isNewUser) {
        const path = this.callBackPathAfterWelcomePage
        this.$router.push({ path })
      } else {
        this.onMaidChanged()
      }
    } 
    // else {
    //   this.$root.$emit('bv::show::modal', 'require-auth-modal')
    // }
  }

  @Watch('bookingForm.location', { deep: true })
  async onLocationChange() {
    console.log('on location change')
    if (this.bookingForm.method === 1 && get(this.bookingForm, 'maid.id')) {
      await this.checkUserLocationWithMaidRadius()
    }
  }

  // get getUserCurrentLocation() {
  //   if(navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(
  //       position => {
  //         console.log(position.coords.latitude);
  //         console.log(position.coords.longitude);
  //       },
  //       error => {
  //         console.log(error.message);
  //       }
  //     )

  //   } else {
  //     console.log("Your browser doesn't support geolocation");
  //   }
  // }

  async onMonthChanged({ month, year }) {
    this.isMonth = month
    // if(this.sameDay.length){
    //   let l = ``
    //  this.calendarValue = l
    // }
    // console.log("month change",this.sameDay);
    // console.log("book date month change", moment(this.bookingForm.date).format('MM'));
    // if()
    // this.bookingForm.date = null;

    if (this.bookingForm.maid) {
      console.log('getMaidBookingTotal 3', month, year)
      await this.getMaidBookingTotal({
        id: this.bookingForm.maid.id,
        month,
        year,
        isMe: true,
      })
      if(this.bookingForm.method === 0){
        this.logDisableDate = this.disabledDates;
        this.disabledDates = [];
      }
    }
  }

  async getMaidBookingTotal({ id, month, year, isMe = true }) {
    const payload = { id, month, year, isMe }
    // console.log(payload)
    await this.fetchMaidCalendarTotalHours(payload)
    const maidCalendar = this.isLoggedIn
      ? await this.fetchMaidCalendarAllMonth(payload)
      : await this.fetchMaidCalendarAllMonthPublic(payload)
    // console.log("maidCalendar",maidCalendar);
    this.disabledDates = getDisabledDateFromTimeSlot(maidCalendar)
  }

  openServiceModal(contentType) {
    if (contentType === 'Terms') {
      this.$router.push({name: 'Terms'})
      // window.open(window.location.origin + '/terms')
    } else {
      this.contentType = contentType
      this.$root.$emit('bv::show::modal', 'agreement-modal')
    }
    this.isAgreePolicy = !this.isAgreePolicy // prevent check by clicling at the text
  }

  doSignIn() {
    this.setCallBackPathAfterWelcomePage('/booking-review')
    this.$root.$emit('bv::hide::modal', 'require-auth-modal')
    this.signIn()
  }

  // Booking detail
  get bookingID() {
    return this.myBookingOneTime.booking.id
  }

  async fetchBookingDetail(fromMounted = false) {
    this.$store.dispatch('showFullContentLoader', true)
    this.isFetching = true
    try {
      this.finishPrepareData = false
      const bookingId = parseInt(this.$route.params.id)
      const result = await ApiClient.bookingDetail(bookingId)
      this.isFetching = false
      if (result.data) {
        const bookingDetail = result.data
        const startDate = new Date(bookingDetail.booking_calendars[0].start_datetime)
        const endDate = new Date(bookingDetail.booking_calendars[0].end_datetime)

        // Move Calendar
        await this.$refs.calendar.focusDate(startDate)
        bookingDetail.maid &&
          (await this.getMaidBookingTotal({
            id: bookingDetail.maid.id,
            month: startDate.getMonth() + 1,
            year: startDate.getFullYear(),
          }))
        console.log('getMaidBookingTotal 4', startDate.getMonth() + 1, startDate.getFullYear())
        this.selectedDate = this.$momentTZ(startDate).locale(this.$i18n.locale).format()
        // find matched options from contant
        const accommodation = Constant.ACCOMMODATION.find(
          (option) => option.value === bookingDetail.accommodation
        )
        const services = Constant.SERVICES.find(
          (option) => option.value === bookingDetail.services.join('_')
        )
        const duration = Constant.DURATION.find((option) => option.value === bookingDetail.duration)
        this.minimumDuration = bookingDetail.duration
        const time = Constant.TIME_OPTIONS.find(
          (option) =>
            option.hour === this.$momentTZ(startDate).hours() &&
            option.minute === this.$momentTZ(startDate).minutes()
        )

        const accommodationText = accommodation
          ? this.$t(`accommodation.${accommodation.text}`)
          : null
        const serviceText = services ? this.$t(`services.${services.text}`) : null
        const method = ['ONE_TIME_AUTO_SELECT', 'ONE_TIME_MANUAL_SELECT'].indexOf(
          bookingDetail.type
        )

        // console.log("FETCHING, METHOD", bookingDetail.type);
        if (bookingDetail.maid !== null) {
          await this.$store.dispatch('maid/addHiddenMaid', bookingDetail.maid.id)
        }

        console.log('fetchbooking detail', bookingDetail)
        const maidObject = get(bookingDetail, 'maid', null)
        // console.log('bookingDetail.maid', maidObject)
        const equipment = this.getDefaultEquipmentOption(bookingDetail)

        const defaultBookingForm = {
          equipment,
          accommodation: { ...accommodation, text: accommodationText },
          services: { ...services, text: serviceText },
          duration: {
            ...duration,
            text: `${duration.value} ${this.$t('form_label.hrs')}`,
          },
          maid: !isEmpty(maidObject) ? { ...maidObject } : null,
          date: startDate.toISOString(),
          additional: bookingDetail.additional_infos,
          time,
          id: bookingDetail.id,
          location: {
            main_text: bookingDetail.location_name,
            description: bookingDetail.location_secondary,
            secondary_text: bookingDetail.location_secondary,
            latitude: bookingDetail.location_latitude,
            longitude: bookingDetail.location_longitude,
            place_id: bookingDetail.location_place_id,
            additional_details: bookingDetail.location_additional_details,
          },
          location_additional_details: bookingDetail.location_additional_details,
          payment: bookingDetail.payment,
          payment_total_price: bookingDetail.payment_total_price,
          total_price: bookingDetail.total_price,
          description: bookingDetail.description,
          method:
            bookingDetail.maid_id && bookingDetail.type === 'ONE_TIME_AUTO_SELECT' ? 1 : method,
          status: bookingDetail.booking_calendars[0].status,
          bookingCalendars: bookingDetail.booking_calendars,
          currentMaid: bookingDetail.maid,
          currentType: bookingDetail.type,
          payment_status: bookingDetail.payment_status,
        }

        this.bookingForm = {
          ...this.bookingForm,
          ...defaultBookingForm,
        }

        console.log('addiwa', this.bookingForm.location_additional_details)

        this.otaHasMaid =
          this.bookingForm.maid !== null &&
          bookingDetail.maid &&
          bookingDetail.type === 'ONE_TIME_AUTO_SELECT'
            ? true
            : false

        if (bookingDetail.payment) {
          this.setPaymentBody({
            refno: bookingDetail.payment.ref_no,
            merchantid: bookingDetail.payment.merchant_id,
            customeremail: bookingDetail.payment.customer_email,
            productdetail: bookingDetail.payment.product_detail,
            total: bookingDetail.payment.total_price,
          })
        }
        // if (bookingDetail.payment_status) {
        //   console.log(bookingDetail.payment_status)
        //   this.bookingForm.payment_status = bookingDetail.payment_status
        // }
        // hide update button if this booking already passed
        this.isHistoryBooking = new Date() > endDate
        this.isCanceled = bookingDetail.booking_calendars[0].status === STATUS.canceledByCustomer
        bookingDetail.maid && (await this.fetchMaidDetail(bookingDetail.maid.id))
        console.log('deduct', bookingDetail.duration)

        // get deduct price
        const deductPrice = calculateDeductPrice(bookingDetail)
        this.bookingForm.deductPrice = deductPrice
        this.deductPrice = deductPrice
        // await this.getDeductPrice(bookingDetail.duration)

        bookingDetail.maid &&
          (await this.getMaidBookingTotal({
            id: bookingDetail.maid.id,
            month: startDate.getMonth() + 1,
            year: startDate.getFullYear(),
            isMe: this.isLoggedIn,
          }))
        console.log('getMaidBookingTotal 5', startDate.getMonth() + 1, startDate.getFullYear())
        await this.updateBookingOnetime(this.bookingForm)

        // console.log("bookingDetail", bookingDetail);
        // console.log("this.durationOptions", this.durationOptions);
        // let dura = null;
        if (!bookingDetail.maid) {
          null
        } else {
          for await (const a of this.durationOptions) {
            if (a.value === bookingDetail.duration) {
              // console.log("this.minimumBoo", a);
              this.bookingForm.duration = a
              // dura = a;
            }
          }
        }

        // Caching current booking
        await this.$store.commit('booking/setCurrentBookingOneTime', {
          equipment: bookingDetail.bring_equipment,
          description: bookingDetail.description,
          additional: bookingDetail.additional_infos,
          date: startDate.toISOString(),
          time,
          location: {
            main_text: bookingDetail.location_name,
            description: bookingDetail.location_secondary,
            secondary_text: bookingDetail.location_secondary,
            latitude: bookingDetail.location_latitude,
            longitude: bookingDetail.location_longitude,
          },

          accommodation: { ...accommodation, text: accommodationText },
          services: { ...services, text: serviceText },
          type: bookingDetail.type,
          duration: bookingDetail.duration,
          method,
          payment_total_price: bookingDetail.payment_total_price,
          maid: bookingDetail.maid,
        })

        await this.setCurrentBookingId(bookingDetail.id)
        this.setCurrentSelectedOnCalendar(startDate.toISOString())
        this.finishPrepareData = true
        this.isFetchBooking = true
      }
      // console.log("this.BookingForm", this.bookingForm);
      // if(this.bookingForm.maid.id){
      //   this.bookingForm.method =1
      // }
      // else{
      //   console.log("no maid");
      // }
    } catch (e) {
      console.log(e)
      this.isFetching = false
    }
    if (this.isMyBookingOneTime && this.prevRoute.name == 'BookingReview' && fromMounted) return
    this.$store.dispatch('showFullContentLoader', false)
    if(fromMounted){
      window.scrollTo(0, 0)
    }
  }

  async fetchMaidDetail(maidID) {
    this.isFetchingMaid = true
    try {
      const result = await ApiClient.maidDetail(maidID)
      this.bookingForm.maid = result.data
      // return ;
    } catch (e) {
      console.log(e)
    }
    this.isFetchingMaid = false
  }

  toggleCancelModal() {
    if(this.checkTimeCurrent()){
      this.$root.$emit('bv::show::modal', 'warning-invalid-datetime')
      return
    }
    this.$root.$emit('bv::show::modal', 'cancel-booking-modal')
  }

  async doUpdateBooking() {
    if(this.checkTimeCurrent()){
      this.$root.$emit('bv::show::modal', 'warning-invalid-datetime')
      return
    }
    // const { hour, minute } = this.bookingForm.time;

    const start_datetime = this.$momentTZ(this.bookingForm.date)
      .hour(this.bookingForm.time.hour)
      .minute(this.bookingForm.time.minute)
      .toISOString()
    const end_datetime = this.$momentTZ(this.bookingForm.date)
      .hour(this.bookingForm.time.hour)
      .minute(this.bookingForm.time.minute)
      .add(this.bookingForm.duration.value, 'hours')
      .toISOString()

    let status
    if (this.oneTime.isChangeMaid) {
      // Booking Unsuccessful, User select new maid for this booking
      status = STATUS.awaiting
    } else if (
      this.bookingForm.status === STATUS.awaiting ||
      this.bookingForm.status === STATUS.updateAwaiting
    ) {
      // Update booking before maid approved
      status = STATUS.updateAwaiting
    } else if (this.isApproved || this.bookingForm.status === STATUS.updateApproved) {
      // Update booking after maid approved
      status = STATUS.updateApproved
    } else {
      // Case update canceled by maid
      status = STATUS.updateAwaiting
    }
    console.log(status)
    const calendar = [
      {
        number: 1,
        start_datetime,
        end_datetime,
        status,
      },
    ]

    // เปลี่ยนจาก OTA -> OTM ไม่ถือว่าเป็นการเปลี่ยนแม่บ้าน
    // ส่วนการเปลี่ยนจาก OTM -> OTA ค่าของ isChangeMaid จะเป็น False
    const isChangeFromOTAtoOTM = this.bookingForm.method !== this.currentBookingOneTime.method
    // &&
    // this.bookingForm.method === 1;

    // this.otaHasMaid ? (this.bookingForm.method = 0) : null;
    this.setBookingReview({
      ...this.bookingForm,
      amount: this.grandTotal,
      type: 'one-time',
      calendar,
      isUpdateBooking: true,
      calendarPassed: [],
      isChangeMaid: this.bookingForm.isChangeMaid && !isChangeFromOTAtoOTM,
    })
    console.log('eeee : this.bookingForm', this.bookingForm)
    await this.updateBookingOnetime(this.bookingForm)

    // localStorage.removeItem('stepOrder');
    this.$router.push({ 
      name: 'BookingReview',
      query: {
        booking_id:this.bookingForm.id,
      },
    })
  }

  async doCancelBooking() {
    const bookingId = parseInt(this.$route.params.id)
    try {
      await ApiClient.cancelBooking(bookingId)
      // ApiClient.adminBookingsCache()

      this.bookingForm = {
        id: null,
        date: null,
        time: null,
        location: {
          description: '',
          main_text: '',
          place_id: '',
          secondary_text: '',
        },
        equipment: null,
        accommodation: null,
        location_additional_details: null,
        duration: null,
        services: null,
        additional: [],
        method: 0,
        maid: null,
        description: '',
        status: '',
        payment: null,
        bookingCalendars: [],
        isChangeMaid: false,
        isChangeMaidBefore: false,
        currentMaid: null,
        deductPrice: 0,
      }
      this.selectedTime = 'Start'
      this.selectedDate = ''
      this.maidCalendarBookingTotalHours = []
      this.disabledDates = []
      this.dropdownText = this.$t('pages.booking.switch_step')
      this.dropdownVariant = 'primary'
      this.selected = false
      this.totalPrice = 0
      this.deductPrice = 0
      this.isAgreePolicy = false
      this.isHistoryBooking = false
      this.minimumDuration = 2 // hr
      this.isCanceled = false
      this.contentType = ''
      await this.resetMaidCalendar()

      console.log('----- set new maid -----')
      const maidDetail = JSON.parse(window.sessionStorage.getItem('maid_detail'))
      if (maidDetail) {
        this.bookingForm.method = 1
        this.bookingForm.maid = maidDetail
        this.updateBookingOnetime(this.bookingForm)
        window.sessionStorage.removeItem('maid_detail')
      }

      this.$router.push({
        name: 'History',
        query: {
          tab: 'refund',
        },
      })
    } catch (e) {
      console.log(e.response)
    } finally {
      this.$root.$emit('bv::hide::modal', 'base-modal')
    }
  }

  onClose() {
    const query = this.$route.query
    // console.log(callback)
    if (query.callback) {
      // console.log(callback)
      // this.$router.push({ path: `${callback}` })
      this.$router.push({ name: query.callback, query: { tab: query.tab } })
    } else {
      localStorage.removeItem('stepOrder');
      this.$router.push({
        name: 'MyBooking',
        query: {
          tab: this.isHistoryBooking ? 'history' : 'upcoming',
        },
      })
    }
  }

  get disableChange() {
    return (
      this.isServiceCompleted ||
      this.isCanceled ||
      (this.upcomingInOneDay && this.isApproved) ||
      this.isReview
    )
  }

  get isServiceCompleted() {
    return this.isHistoryBooking && this.isApproved
  }

  get isPaymentConfirmed() {
    return this.bookingForm.status === STATUS.awaiting
  }

  get upcomingInOneDay() {
    // console.log("upcoming 1");
    if (!this.bookingForm.date || this.isBookingOneTime) return false
    // console.log("upcoming 2");
    if (!this.bookingForm.time || !this.bookingForm.duration) return false
    // console.log("upcoming 3", this.bookingForm.time);
    if (this.$momentTZ(this.bookingForm.date).diff(this.momentCurrentDate, 'seconds') > 60 * 60 * 4)
      return false

    return (
      // moment(this.bookingForm.date)
      //   .add(this.bookingForm.time.hour, "hours")
      //   .add(this.bookingForm.time.minute, "minutes")
      //   .diff(moment(), "seconds") <
      // 60 * 60 * 24
      this.$momentTZ(this.bookingForm.date).diff(this.momentCurrentDate, 'seconds') < 60 * 60 * 3 &&
      this.isApproved
    )
  }

  get isNoAvailableMaid() {
    return this.bookingForm.status === STATUS.noAvailableMaid
  }

  get calendar() {
    if (!this.bookingForm.time || !this.bookingForm.duration) return {}
    const { hour, minute } = this.bookingForm.time
    if (!hour || !minute) return {}
    const start_datetime = this.$momentTZ(this.bookingForm.date)
      .hour(hour)
      .minute(minute)
      .second(0)
      .millisecond(0)
      .toISOString()
    const end_datetime = this.$momentTZ(this.bookingForm.date)
      .hour(hour)
      .minute(minute)
      .second(0)
      .millisecond(0)
      .add(this.bookingForm.duration.value, 'hours')
      .toISOString()
    return { start_datetime, end_datetime, number: 1 }
  }

  get momentCurrentDate() {
    let current_date = localStorage.getItem('currentDate') || new Date()
    let moment_date = this.$momentTZ(current_date)
    let adminToken = window.localStorage.getItem('admin_token')
    if (adminToken && adminToken !== 'null') {
      moment_date = this.$momentTZ()
    }
    return moment_date
  }

  get isPassed() {
    return this.momentCurrentDate > this.$momentTZ(this.calendar.end_datetime) 
  }

  // เช็คว่าจ่ายเงินเกิน 1 วันแล้วหรือยัง ?
  // หากเกิน 1 วันแล้วไม่มีการตอบรับ ลูกค้าจะต้องเปลี่ยนวันเวลา
  get isTimeoutOfAppproval() {
    if (this.isNoAvailableMaid) return true
    if (!this.bookingForm.payment) return false
    const isTimeoutOfAppproval =
      this.momentCurrentDate.diff(this.$momentTZ(this.bookingForm.payment.create_datetime), 'seconds') >
      60 * 60 * 24
    return isTimeoutOfAppproval
  }

  // Booking Unsuccessful
  get isBookingUnSuccessful() {
    console.log('this.bookingForm.status', this.bookingForm.status)
    if (this.isServiceCompleted && this.bookingForm.status !== STATUS.noAvailableMaid) return false

    if (
      this.bookingForm.status === STATUS.noAvailableMaid ||
      this.bookingForm.status === STATUS.canceledByMaid ||
      this.bookingForm.status === STATUS.awaiting
    )
      return true

    if (this.isHistoryBooking) {
      return this.bookingForm.status !== STATUS.approved
    }

    // Manual Select
    if (this.bookingForm.method === 1) {
      return this.bookingForm.status === STATUS.canceledByMaid
    }

    // Auto select (Maybe this condition is not used)
    if (this.bookingForm.payment) {
      return this.bookingForm.status === STATUS.awaiting && this.isTimeoutOfAppproval
    }

    return false
  }

  get badgeTooltipText() {
    if (this.bookingForm.payment_status === 'BANK_TRANSFER_REVIEWING') {
      return this.$t('status.your_bank_transfer_is_under_review')
    }
    if (this.isBookingUnSuccessful) {
      return this.bookingForm.method === 1
        ? this.$t('form_label.sorry_maid_cannot_accept')
        : this.$t('form_label.sorry_no_maid_available_for_auto_select')
    }
    return this.bookingForm.status === STATUS.awaiting
      ? this.$t('status.waiting_for_maids_confirmation')
      : ``
  }

  get badgeLabel() {
    // console.log("this.isReview", this.isReview);
    if (this.isReview) {
      return this.$t('status.bank_transfer_reviewing')
    }
    if (this.isBookingUnSuccessful) return this.$t('status.booking_unsuccessful')

    switch (this.bookingForm.status) {
      case STATUS.approved:
        return this.$t('status.booking_confirmed')
      case STATUS.awaiting:
        return this.$t('status.payment_confirmed')
      default:
        return ''
    }
  }

  get badgeVaraint() {
    if (this.isBookingUnSuccessful) return `danger`

    switch (this.bookingForm.status) {
      case STATUS.approved:
        return `success`
      case STATUS.awaiting:
        return `primary`
      default:
        return ''
    }
  }

  get isUserChangeAnyField() {
    if (this.isFetching) return false
    let zero = 0

    // Additional
    const additionalBefore = this.currentBookingOneTime.additional.sort().toString()
    const additionalAfter = this.bookingForm.additional.sort().toString()
    const isUpdateAdditional = additionalBefore !== additionalAfter

    // Description
    const isUpdateDescription =
      this.currentBookingOneTime.description !== this.bookingForm.description

    // Location
    let isUpdateLocation = false
    if (this.currentBookingOneTime.location.main_text !== this.bookingForm.location.main_text) {
      isUpdateLocation = true
    }
    if (
      this.bookingForm.location.additional_details &&
      this.bookingForm.location.additional_details !== this.oneTime.location_additional_details
    ) {
      isUpdateLocation = true
    }
    // this.currentBookingOneTime.location.main_text !== this.bookingForm.location.main_text ||
    //  this.bookingForm.location.additional_details
    // this.oneTime.location_additional_details

    // console.log(this.oneTime)

    // Service
    const isUpdateServices =
      this.bookingForm.services &&
      this.currentBookingOneTime.services &&
      this.bookingForm.services.value !== this.currentBookingOneTime.services.value

    const isUpdateDuration =
      this.bookingForm.duration &&
      this.bookingForm.duration.value !== this.currentBookingOneTime.duration

    const isChangeMaid = this.oneTime.isChangeMaid

    let isChangeMethod = null

    if (this.otaHasMaid && zero === 0) {
      isChangeMethod = false
      zero = 1
    } else {
      isChangeMethod = this.bookingForm.method !== this.currentBookingOneTime.method
    }

    return (
      isUpdateAdditional ||
      isUpdateDescription ||
      this.isUpdateDate ||
      isUpdateLocation ||
      this.isUpdateAccommodation ||
      isUpdateServices ||
      isUpdateDuration ||
      isChangeMaid ||
      isChangeMethod
    )
  }

  get isUpdateAccommodation() {
    return this.bookingForm.accommodation &&
    this.currentBookingOneTime.accommodation &&
    this.bookingForm.accommodation.value !== this.currentBookingOneTime.accommodation.value
  }

  get isUpdateDate() {
    if (!this.bookingForm.date || !this.currentBookingOneTime.date) return false
    const isUpdateDate = this.$momentTZ(this.currentBookingOneTime.date).format('YYYY-MM-DD') !==
      this.$momentTZ(this.bookingForm.date).format('YYYY-MM-DD') || 
      (this.currentBookingOneTime.time &&
        this.bookingForm.time &&
        (this.currentBookingOneTime.time.hour !== this.bookingForm.time.hour ||
          this.currentBookingOneTime.time.minute !== this.bookingForm.time.minute))
    return isUpdateDate
  }

  get canCancelBooking() {
    return this.isBookingUnSuccessful || !(this.upcomingInOneDay && this.isApproved)
  }

  get canUpdateBooking() {
    const isSelectDuration = this.bookingForm.duration !== null
    if (this.isBookingUnSuccessful) {
      return (
        this.isUserChangeAnyField &&
        this.isSelectAvailableDate &&
        !this.isFetchingPrice &&
        this.isMaidPublished &&
        isSelectDuration &&
        !this.hasEquipmentError &&
        this.isSelectedServiceOptionValidWithMaid &&
        !this.isUserLocationOutOfMaidRadius
      )
    } else {
      // console.log("hoyya");
      // console.log("hoyya_2", this.upcomingInOneDay);
      return (
        !(this.upcomingInOneDay && this.isApproved) &&
        this.isUserChangeAnyField &&
        this.isSelectAvailableDate &&
        !this.isFetchingPrice &&
        this.isMaidPublished &&
        isSelectDuration &&
        !this.hasEquipmentError &&
        this.isSelectedServiceOptionValidWithMaid &&
        !this.isUserLocationOutOfMaidRadius
      )
    }
  }

  get disabledAddress() {
    // return !this.isBookingOneTime // Note: PD197B - ปิดการอัปเดทที่อยู่ในทุกกรณี
    return this.disableChange || this.isApproved || this.isUpdateApproved // DON'T REMOVE THIS COMMENT
  }

  get isUpdateApproved() {
    return this.bookingForm.status === STATUS.updateApproved
  }

  get isApproved() {
    console.log('this.isApprove', this.bookingForm)
    // if(this.bookingForm.maid !== null && this.bookingForm.method === 0) {
    //   this.bookingForm.method = 1;
    // }
    return this.bookingForm.status === STATUS.approved
  }

  get isReview() {
    return get(this.bookingForm, 'payment_status') === 'BANK_TRANSFER_REVIEWING'
  }

  get requireCheckMaidRadius() {
    return Boolean(
      this.bookingForm.method === 1 &&
        get(this.bookingForm, 'maid.id') &&
        // this.isBookingOneTime &&
        get(this.bookingForm, 'location.latitude') &&
        get(this.bookingForm, 'location.longitude')
    )
  }

  get isUserLocationOutOfMaidRadius() {
    return this.requireCheckMaidRadius && this.isMaidOutOfRadius && !this.disabledAddress
  }

  // use for booking status
  get booking() {
    const { status, maid, payment } = this.bookingForm

    // Calculate end_datetime
    const { date, duration } = this.currentBookingOneTime
    let end_datetime
    if (date && duration) {
      end_datetime = this.$momentTZ(date).add(duration, 'hour').toISOString()
    }

    return {
      end_datetime,
      status,
      maid,
      payment,
      payment_status: this.bookingForm.payment_status || null,
      type:
        this.bookingForm.method === 1
          ? Constant.ONE_TIME_MANUAL_SELECT
          : Constant.ONE_TIME_AUTO_SELECT,
    }
  }

  get shouldShowBookingStatus() {
    return (
      !this.isBookingOneTime &&
      !this.isFetching &&
      !this.oneTime.isChangeMaid &&
      this.finishPrepareData
    )
  }

  get isMaidPublished() {
    let ans = null

    // if (this.bookingForm.method === 0 || !this.bookingForm.maid) return true
    if (this.bookingForm.method === 0 || !this.bookingForm.maid) {
      ans = true
    } else if (this.bookingForm.method === 1) {
      // console.log("this.publish_status", this.publish_status);
      ans = this.bookingForm.maid && this.publish_status === 'PUBLISHED'
    }
    return ans
  }

  get showChatBtn() {
    if (this.isBookingOneTime || this.oneTime.isChangeMaid) return false
    if (
      !this.bookingForm.maid ||
      this.bookingForm.maid.user.phone == null ||
      this.bookingForm.maid.user.phone == ''
    )
      return false
    return true
  }

  get isaAllergicMaid() {
    if (this.bookingForm.additional.length > 0 && this.bookingForm.method == 1  && this.bookingForm.maid) {
      const allergic_cat = this.bookingForm.additional.includes('HAS_CAT') && this.bookingForm.maid.additional_infos.includes("ALLERGIC_CAT")
      const allergic_dog = this.bookingForm.additional.includes('HAS_DOG') && this.bookingForm.maid.additional_infos.includes("ALLERGIC_DOG")
      
      if(allergic_cat && allergic_dog){
        return this.$t('warning.allergic_cat_dog')
      } else if(allergic_cat){
        return this.$t('warning.allergic_cat')
      } else if(allergic_dog){
        return this.$t('warning.allergic_dog')
      } else {
        return ''
      }
    } else {
      return ''
    }
  }

  // async toChatPage() {
  //   const channel = await this.$store.dispatch(
  //     'sbChannel/getChannelByUserID',
  //     this.oneTime.currentMaid.user.id
  //   )
  //   if (channel) {
  //     const user = this.$store.state.sbUser.sbUser
  //     this.$router.push({
  //       path: `/chats/message/${user.userId}/${channel.url}`,
  //       query: { from: 'bookingDetail' },
  //     })
  //   }
  // }
  isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  }
  toCallMaid() {
    if (this.isMobile()) {
      // window.open(`tel:${this.bookingForm.maid.user.phone}`, '_system')
      const link = document.createElement('a')
      link.href = `tel:${this.bookingForm.maid.user.phone}`
      link.click()
    } else {
      this.$root.$emit('bv::show::modal', 'maid-phone-number')
    }
  }

  async checkUserLocationWithMaidRadius() {
    try {
      if (this.requireCheckMaidRadius) {
        this.isFetching = true
        this.isMaidOutOfRadius = await this.inquiryMaidOutOfRadius({
          latitude: get(this.bookingForm, 'location.latitude'),
          longitude: get(this.bookingForm, 'location.longitude'),
          maidId: get(this.bookingForm, 'maid.id'),
        })
      }
    } catch (error) {
      console.log(error)
    } finally {
      this.isFetching = false
    }
  }

  async confirmCondoWarning() {
    if (this.dontShowCondoWarning) {
      try {
        await ApiClient.updateUserAccommodationConfirmation()
        await this.updateUserAccommodation()
      } catch (error) {
        console.log(error)
      }
    }

    this.condoWarningDisplayed = true
    this.$root.$emit('bv::hide::modal', 'confirm-condo-modal')
  }

  dateTimeTimezone = ''
  getDateTimeTimezone() {
    const d = new Date();

    // Get date components
    const day = d.getDate();
    const month = d.getMonth() + 1; // Month is zero-based, so add 1
    const year = d.getFullYear();

    // Get time components
    const hours = d.getHours();
    const minutes = d.getMinutes();

    // Get timezone offset in minutes
    const timezoneOffset = d.getTimezoneOffset();

    // Convert timezone offset to hours and minutes
    const absTimezoneOffsetHours = Math.abs(timezoneOffset / 60);
    const timezoneOffsetHours = Math.floor(absTimezoneOffsetHours);
    const timezoneOffsetMinutes = Math.abs(timezoneOffset % 60);

    // Determine the sign of the timezone offset
    const timezoneSign = timezoneOffset >= 0 ? '-' : '+';

    // Format the date/time and timezone
    const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
    const formattedTimezone = `(GMT${timezoneSign}${timezoneOffsetHours < 10 ? '0' : ''}${timezoneOffsetHours}:${timezoneOffsetMinutes < 10 ? '0' : ''}${timezoneOffsetMinutes})`;

    return `${formattedDateTime} ${formattedTimezone}`;
  }

}
</script>
<style lang="scss" scoped>
.text-timestamp {
  font-size: 12px;
  margin-top: 10px;
  opacity: 0.7;
}

.modal-title {
  color: black;
  font-weight: bold;
}
.btn-close {
  background: transparent;
  border: none;
  outline: none;
  vertical-align: middle;
  color: #757575;
  &:hover {
    color: black;
  }
}
</style>
<style lang="scss">
.avatar {
  width: 64px;
  height: 64px;
  border-radius: 50%;
}
.marb8 {
  margin-bottom: 8px !important;
}
@media screen and (min-width: 320px) and (max-width: 375px) {
  .tooltip-inner {
    max-width: 240px;
    margin-left: 30px;
  }
}
@media screen and (min-width: 375px) and (max-width: 425px) {
  .tooltip-inner {
    max-width: 300px;
    margin-left: 30px;
  }
}
@media screen and (min-width: 425px) and (max-width: 767px) {
  .tooltip-inner {
    max-width: 330px;
    margin-left: 40px;
  }
}
@media screen and (min-width: 768px) and (max-width: 2560px) {
  .tooltip-inner {
    max-width: 400px;
    margin-left: 2rem;
  }
}

.layout-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.layout-dropdown {
  display: flex;
  gap: 10px;
}

.custom-dropdown .dropdown-toggle {
  background-color: white;
  color: #005EFF;
  display: flex;
  align-items: center;
}

.custom-dropdown .dropdown-toggle:hover {
  background-color: #005EFF;
  color: white;
}

.selected-item .dropdown-toggle {
  background-color: #005EFF;
  color: white;
  position: relative;
}
</style>
