export const isPlaceInAvailableArea = (place, availableArea) => {
  const { terms } = place
  return terms.some(term => {
    const subDistrict = term.value.toLowerCase()
    return availableArea.some(area => {
      const areaTH = area.name_th.toLowerCase()
      const areaEN = area.name_en.toLowerCase()
      return subDistrict.includes(areaTH) || subDistrict.includes(areaEN)
    })
  })
}

export const isPlaceInAvailableArea2 = (place, availableArea) => {
  const { latitude, longitude } = place;
  return availableArea.some(area => {
    const { lat: areaLat, long: areaLng, radius } = area;
    const distance = getDistance(latitude, areaLat, longitude, areaLng);
    console.log('distance2', distance)
    return distance <= radius;
  });
};

export const getDistance = (lat1, lat2, lon1, lon2) => {
  lon1 = lon1 * Math.PI / 180;
  lon2 = lon2 * Math.PI / 180;
  lat1 = lat1 * Math.PI / 180;
  lat2 = lat2 * Math.PI / 180;
    
  let dlon = lon2 - lon1; 
  let dlat = lat2 - lat1;
  let a = Math.pow(Math.sin(dlat / 2), 2)
        + Math.cos(lat1) * Math.cos(lat2)
        * Math.pow(Math.sin(dlon / 2),2);
        
  let c = 2 * Math.asin(Math.sqrt(a));

  let r = 6371;

  return(c * r).toFixed(4);
}

export const defaultMapsCenter = Object.freeze({ lat: 13.7506, lng: 100.5316 })
