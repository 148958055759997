<template>
<div>
  <b-container v-show="showBookingReview" class="container-content card-container shadow-lg translateYg">
    <b-alert variant="danger text-center" :show="bookingReview.isUpdateBooking">
      {{ $t('warning.please_press_confirm_to_update') }}
    </b-alert>
    <div class="page-header">
      <h4 class="page-header-text">{{ headerText }}</h4>
    </div>
    <div v-if="isSubmit">
      <div class="text-center mb-4" id="bankTransfer">
        <h4>
          {{ $t('booking_review.your_payment_slip_has_been_submitted_successfully') }}
        </h4>

        <p>
          {{ $t('booking_review.your_payment_will_be_reviewed_soon') }}
        </p>
        <b-row class="my-3 justify-content-center">
          <b-card
            tag="article"
            class="mb-2"
            style="width: 900px; border-radius: 15px !important"
            border-variant="dark"
          >
            <b-card-text class="mb-0">
              <b-row>
                <b-col cols="6" class="pr-1 text-right">
                  <p>{{ $t('booking_review.amount_to_be_transferred') }}</p>
                </b-col>
                <b-col cols="6" class="ml-0 pl-1 text-primary text-left">
                  <p>
                    ฿ {{ totalString | toCurrencyNumber }}
                    {{ $t('booking_review.total') }}
                  </p>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6" class="pr-1 text-right">
                  <p>{{ $t('booking_review.bank_name') }}</p>
                </b-col>
                <b-col cols="6" class="ml-0 pl-1 text-primary text-left">
                  <p>{{ $t('booking_review.kasikorn') }}</p>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6" class="pr-1 text-right">
                  <p>{{ $t('booking_review.account_number') }}</p>
                </b-col>
                <b-col cols="6" class="ml-0 pl-1 text-primary text-left">
                  <p>{{ $t('booking_review.058-1-98209-7') }}</p>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6" class="pr-1 text-right">
                  <p>{{ $t('booking_review.account_name') }}</p>
                </b-col>
                <b-col cols="6" class="ml-0 pl-1 text-primary text-left">
                  <p>{{ $t('booking_review.blue_services') }}</p>
                </b-col>
              </b-row>
            </b-card-text>
            <b-img
              v-if="image && slip"
              center
              :src="slip"
              height="200"
              class="mb-3"
              style="border-radius: 10px"
              @click="showImage(slip)"
            ></b-img>

            <!-- <b-button
              href="#"
              variant="primary"
              @click="uploadImage"
              :disabled="isLoading"
            > -->
            <!-- <b-spinner small class="mr-2" v-if="isLoading"></b-spinner>
              {{ $t("booking_review.upload_payment_slip") }}</b-button
            > -->
            <!-- <input
              id="main_image"
              class="d-none"
              type="file"
              name="photo"
              @change="importMainImage($event)"
            /> -->
            <!-- <p v-if="image && slip" class="mt-3">{{ image.name }}</p> -->
          </b-card>
        </b-row>
        <b-row class="justify-content-center">
          <b-button href="#" variant="primary" @click="toMyBooking">
            {{ $t('button.return_to_my_booking') }}</b-button
          >
        </b-row>
        <!-- <b-row class="justify-content-center">
          <b-button
            href="#"
            variant="primary"
            @click="uploadImage"
            :disabled="!slip"
          >
            {{ $t("booking_review.submit") }}</b-button
          >
        </b-row> -->
      </div>
    </div>

    <!-- Update Booking -->
    <!-- Update with no additional price -->

    <div v-if="bookingReview.isUpdateBooking && parseInt(amount) <= 0">
      <div>
        <b-row>
          <b-col xl="3"></b-col>
          <b-col xl="6" class="px-4">
            <div v-if="bookingReview.method === 1" class="d-flex mb-3">
              <img
                :src="bookingReview.maid.user.image_url"
                class="avatar mr-4"
                v-if="bookingReview.method === 1"
              />
              <div>
                <p class="fullname text-grey" v-if="bookingReview.method === 1">
                  {{ fullName }}
                </p>
              </div>
            </div>
            <BookedCredits v-if="!isBookingOneTime" />
            <div>
              <p class="date text-grey" v-if="startDateLabel && isBookingOneTime">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-calendar mb-1 mr-1"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"
                  />
                </svg>
                {{ startDateLabel }}
              </p>
              <p class="cleaning-type text-grey" v-if="isBookingOneTime">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-clock mb-1 mr-1"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"
                  />
                  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                </svg>
                {{ serviceTime }}
              </p>
              <p class="cleaning-type text-grey">
                <img src="@/assets/images/icons/broom.svg" width="20" height="20" class="mb-1" />
                {{ service }}
              </p>
              <div class="cleaning-type text-grey d-flex">
                <div class="mr-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-geo-alt-fill mb-1 mr-1"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"
                    />
                  </svg>
                </div>
                <div>
                  {{ location }} {{ place }}
                  <div v-if="additional_details" class="cleaning-type text-grey d-flex"></div>
                  {{ additional_details }}
                </div>
              </div>
            </div>

            <!-- <div id="map" ref="gmaps" style="height: 300px" class="my-2"></div> -->
            <div class="map-container">
            <iframe
              v-if="true"
              class="my-2 map-iframe"
              :src="`https://maps.google.com/maps?q=${bookingReview.location.latitude}, ${bookingReview.location.longitude}&t=&z=14&ie=UTF8&iwloc=&output=embed`"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
            ></iframe>    
            </div>   
            <p class="cleaning-type text-grey">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-info-circle mb-1 mr-1"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path
                  d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"
                />
              </svg>
              {{ $t('form_label.additional_info') }}
            </p>
            <b-form-group class="text-grey mb-0">
              <ol class="mb-0">
                <li v-for="(a, i) in additional" :key="`additional-${i}`" class="col-8">
                  {{ a }}
                </li>
              </ol>
            </b-form-group>

            <p class="cleaning-type text-grey description">
              {{ bookingReview.description || `-` }}
            </p>

            <b-col lg="12" class="pb-4 pt-2 px-0">
              <p
                :class="{ 'text-danger': !equipment.bring_equipment }"
                v-if="!bookingReview.isUpdateBooking"
              >
                {{ equipment.text }}
              </p>
            </b-col>
            <b-col>
              <b-form-row v-if="!isBank && !isSubmit">
                <b-col cols="4" md="3">
                  <b-button
                    variant="link"
                    size="md"
                    class="py-2 text-underline"
                    block
                    @click="goBack"
                    >{{ $t('button.go_back') }}</b-button
                  >
                </b-col>
                <b-col cols="8" md="9">
                  <b-button
                    variant="light"
                    size="md"
                    class="py-2 align-items-center"
                    @click="doBooking"
                    block
                    type="submit"
                    :disabled="canSelectDate"
                    style="background: #afb1b0; border-color: #afb1b0; color: white !important"
                    v-if="!canSelectDate"
                  >
                    {{ confirmButtonText }}
                  </b-button>
                  <b-button
                    variant="primary"
                    size="md"
                    @click="doBooking"
                    class="py-2 align-items-center"
                    block
                    type="submit"
                    :disabled="isLoading"
                    v-else
                  >
                    <b-spinner small class="mr-2" v-if="isLoading"></b-spinner>
                    {{ confirmButtonText }}
                  </b-button>
                </b-col>
              </b-form-row>
            </b-col>
          </b-col>
          <b-col xl="3"></b-col>
        </b-row>
      </div>
    </div>
    <!-- Update with additional price -->
    <b-row
      v-if="
        !bookingReview.isUpdateBooking || (bookingReview.isUpdateBooking && parseInt(amount) > 0)
      "
    >
      <b-col xl="6" class="px-4">
        <div v-if="bookingReview.method === 1" class="d-flex mb-3">
          <img
            :src="bookingReview.maid.user.image_url"
            class="avatar mr-4"
            v-if="bookingReview.method === 1"
          />
          <div>
            <p class="fullname text-grey" v-if="bookingReview.method === 1">
              {{ fullName }}
            </p>
          </div>
        </div>
        <div>
          <p class="date text-grey" v-if="startDateLabel">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-calendar mb-1 mr-1"
              viewBox="0 0 16 16"
            >
              <path
                d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"
              />
            </svg>
            {{ startDateLabel }}
          </p>
          <p class="cleaning-type text-grey">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-clock mb-1 mr-1"
              viewBox="0 0 16 16"
            >
              <path
                d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"
              />
              <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
            </svg>
            {{ serviceTime }}
          </p>
          <p class="cleaning-type text-grey">
            <img
              :src="require('@/assets/images/icons/broom.svg')"
              width="20"
              height="20"
              class="mb-1"
            />
            {{ service }}
          </p>
          <div class="cleaning-type text-grey d-flex">
            <div class="mr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-geo-alt-fill mb-1 mr-1"
                viewBox="0 0 16 16"
              >
                <path
                  d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"
                />
              </svg>
            </div>
            <div>{{ location }} {{ place }}</div>
          </div>
          <div v-if="additional_details" class="cleaning-type text-grey d-flex">
            <img
              :src="require('@/assets/images/icons/notepad.svg')"
              width="20"
              height="20"
              class="mr-1"
            />
            <div style="word-break: break-word">{{ additional_details }}</div>
          </div>
        </div>
        <!-- <address-detail :address="bookingReview.location"></address-detail> -->

        <!-- MAP -->
        <!-- <div id="map" ref="gmaps" style="height: 300px" class="my-2"></div> -->
        <div class="map-container">
        <iframe
          v-if="true"
          class="my-2 map-iframe"
          :src="`https://maps.google.com/maps?q=${bookingReview.location.latitude}, ${bookingReview.location.longitude}&t=&z=14&ie=UTF8&iwloc=&output=embed`"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
          ></iframe>    
        </div>    
        <p class="cleaning-type text-grey">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-info-circle mb-1 mr-1"
            viewBox="0 0 16 16"
          >
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path
              d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"
            />
          </svg>
          {{ $t('form_label.additional_info') }}
        </p>
        <b-form-group class="text-grey mb-0">
          <ol class="mb-0">
            <li v-for="(a, i) in additional" :key="`additional-${i}`" class="col-8">
              {{ a }}
            </li>
          </ol>
        </b-form-group>
        <!-- <div class="w-100"> -->
        <p class="cleaning-type text-grey description">
          {{ bookingReview.description || `-` }}
        </p>
        <b-col lg="12" class="pb-4 pt-2 px-0">
          <p :class="{ 'text-danger': !equipment.bring_equipment }">{{ equipment.text }}</p>
        </b-col>
      </b-col>

      <b-col xl="6" class="px-4">
        <div>
          <div class="d-flex justify-content-between font-weight-bold underline mb-3">
            <p class="text-black">{{ $t('booking_review.booking') }}</p>
            <p>{{ bookingType }}</p>
          </div>
        </div>
        <BookedCredits v-if="!isBookingOneTime" />

        <div>
          <div class="d-flex justify-content-between font-weight-bold underline mb-3">
            <p class="text-black">{{ bookingReview.duration.value }} {{ $t('alias.hour') }} <small class="text-grey" style="white-space:nowrap;">{{ choseLessThanRecommended }}</small></p>
            <p style="white-space:nowrap;">฿ {{ amount | toCurrencyNumber }}</p>
          </div>
        </div>
        <div>
          <div class="d-flex justify-content-between font-weight-bold underline mb-3">
            <p class="text-black">{{ $t('booking_review.discount') }}</p>
            <p>฿ {{ totalDiscount > 0 ? '-' : '' }} {{ totalDiscount | toCurrencyNumber }}</p>
          </div>
        </div>
        <div>
          <div class="d-flex justify-content-between font-weight-bold underline mb-3">
            <p class="text-black">{{ $t('booking_review.vat') }}</p>
            <p>฿ {{ vat | toCurrencyNumber }}</p>
          </div>
        </div>

        <div class="d-flex justify-content-between font-weight-bold underline mb-3">
          <p class="text-black">
            {{ $t('alias.total') }}
          </p>
          <p>฿ {{ totalString ? totalString : amount | toCurrencyNumber }}</p>
        </div>
        <!-- </div>
        </div> -->
        <!-- <b-form-group
          :label="$t('form_label.coupon')"
          v-if="!bookingReview.isUpdateBooking && showApplyCouponSection"
        > -->
        <b-form-group
          :label="$t('form_label.coupon')"
          v-if="!bookingReview.isUpdateBooking && !isSubmit"
        >
          <b-input-group>
            <b-form-input
              :placeholder="$t('form_label.apply_coupon')"
              v-model="coupon"
            ></b-form-input>
            <b-input-group-append>
              <b-button variant="primary" @click="applyCoupon">{{ $t('button.apply') }}</b-button>
            </b-input-group-append>
          </b-input-group>
          <small
            :class="{
              'text-success': isApplySuccess,
              'text-danger': !isApplySuccess,
            }"
          >
            {{ couponStatusLabel }}
          </small>
        </b-form-group>

        <b-form-group
          :label="$t('booking_review.payment_method')"
          v-if="!isSubmit && parseInt(amount) > 0"
        >
          <b-input-group @change="isBank ? (isBank = !isBank) : null">
            <b-col class="d-flex flex-wrap px-0" cols="12">
              <b-col cols="12" sm="12" md="6" lg="6" xl="7" class="px-0">
                <b-form-radio
                  required
                  v-model="paymentType"
                  class="w-100 text-grey"
                  name="some-radios"
                  value="credit"
                  :class="{ 'text-primary': paymentType === 'credit' }"
                >
                  {{ $t('booking_review.credit_debit') }}
                </b-form-radio>
              </b-col>
              <b-col
                :class="{ 'text-right': !isMobileXS, 'text-left': isMobileXS }"
                cols="12"
                sm="12"
                md="6"
                lg="6"
                xl="5"
                class="mt-1 px-0"
              >
                <img src="./../../assets/images/credit_icon.png" alt="" /></b-col
            ></b-col>
            <b-col class="d-flex flex-wrap px-0 pt-2" cols="12">
              <b-col cols="12" sm="12" md="6" lg="6" xl="7" class="px-0">
                <b-form-radio
                  v-model="paymentType"
                  class="w-100 text-grey mt-2"
                  name="some-radios"
                  value="bank"
                  :class="{ 'text-primary': paymentType === 'bank' }"
                  >{{ $t('booking_review.bank_transfer') }}
                </b-form-radio>
              </b-col>
              <b-col
                :class="{ 'text-right': !isMobileXS, 'text-left': isMobileXS }"
                cols="12"
                sm="12"
                md="6"
                lg="6"
                xl="5"
                class="mt-1 px-0"
              >
                <img src="./../../assets/images/bank_icon.png" alt="" /> </b-col
            ></b-col>
          </b-input-group>
        </b-form-group>
        <!-- <div class="warning-confirm" v-if="bookingReview.isUpdateBooking">
          <label class="warning-maid">
            {{ $t('warning.please_press_confirm_to_update') }}
          </label>
        </div> -->
        <b-form-row v-if="!isBank && !isSubmit">
          <b-col cols="4" md="3">
            <b-button variant="link" size="md" class="py-2 text-underline" block @click="goBack">{{
              $t('button.go_back')
            }}</b-button>
          </b-col>
          <b-col cols="8" md="9">
            <b-button
              variant="light"
              size="md"
              class="py-2 align-items-center"
              @click="doBooking"
              block
              type="submit"
              :disabled="canSelectDate"
              style="background: #afb1b0; border-color: #afb1b0; color: white !important;"
              v-if="!canSelectDate"
            >
              {{ confirmButtonText }}
            </b-button>
            <b-button
              variant="primary"
              size="md"
              @click="doBooking"
              class="py-2 align-items-center"
              block
              type="submit"
              :disabled="isLoading"
              v-else
            >
              <b-spinner small class="mr-2" v-if="isLoading"></b-spinner>
              {{ confirmButtonText }}
            </b-button>
          </b-col>
        </b-form-row>
      </b-col>
    </b-row>
    <BaseModal id="warning-upload-image-fail">
      <div class="text-center">
        <p>{{ $t('warning.upload_image_fail') }}</p>
      </div>
    </BaseModal>
    <BaseModal id="warning-cannotselectday" size="lg">
      <div class="text-center py-5">
        <p>{{ $t('warning.you_have_booked_within_24_hour') }} {{ time_can_pay }}</p>
      </div>
    </BaseModal>
    <BaseModal id="warning-have-another-booking" >
      <div class="text-center">
        <p>{{ $t('warning.have_another_booking') }}</p>
      </div>
    </BaseModal>
    <BaseModal id="warning-invalid-datetime" >
      <div class="text-center">
        <p>{{ $t('error.invalid_datetime') }}</p>
        <p class="text-timestamp">{{ dateTimeTimezone }}</p>
      </div>
    </BaseModal>
    <BaseModal id="booking-suspended-modal" :hideCloseButton="true">
      <p>{{ $t('warning.suspended_modal') }}</p>
      <b-button block variant="primary" @click="goBack" >
        {{ $t('booking_review.back_to_booking_page') }}
      </b-button>
    </BaseModal>
    <BaseModal id="slip-img" size="lg" centerd="true">
      <b-img
        center
        :src="image"
        alt="Center image"
        class="mt-3 mb-5 mx-0 px-0 width-image-modal"
        style="border-radius: 10px"
      ></b-img>
    </BaseModal>
    <div v-if="isBank && !isSubmit">
      <hr class="mt-4 mb-5" />
      <div class="text-center" id="bankTransfer">
        <h5 class="text-primary">
          {{ $t('booking_review.the_booking_has_not_been_made_yet') }}
        </h5>
        {{ $t('booking_review.please_upload_your_payment_slip_to_complete_your_booking') }}
        <h5 class="my-3 text-grey" style="font-size: 1rem;">
          {{ $t('booking_review.time_limit') }}
          {{ countDownHour }}
          ( {{ timeAt }} )
        </h5>

        <b-row class="my-3 justify-content-center">
          <b-card
            tag="article"
            class="mb-2"
            style="width: 900px; border-radius: 15px !important"
            border-variant="dark"
          >
            <b-card-text class="mb-0">
              <b-row>
                <b-col cols="6" class="pr-1 text-right">
                  <p>
                    {{ $t('booking_review.amount_to_be_transferred') }}
                    <span style="font-size: 28px;">
                      &nbsp;
                    </span>
                  </p>

                </b-col>
                <b-col cols="6" class="ml-0 pl-1 text-primary text-left">
                  <p>
                    <span style="font-size: 28px;">฿ {{ totalString | toCurrencyNumber }} </span> {{ $t('booking_review.total')
                    }}{{ $t('booking_review.including_vat') }}
                  </p>
                  <!-- <p>{{ $t("booking_review.including_vat") }}</p> -->
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6" class="pr-1 text-right">
                  <p>{{ $t('booking_review.bank_name') }}</p>
                </b-col>
                <b-col cols="6" class="ml-0 pl-1 text-primary text-left">
                  <p>{{ $t('booking_review.kasikorn') }}</p>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6" class="pr-1 text-right">
                  <p>{{ $t('booking_review.account_number') }}</p>
                </b-col>
                <b-col cols="6" class="ml-0 pl-1 text-primary text-left">
                  <p>{{ $t('booking_review.058-1-98209-7') }}</p>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6" class="pr-1 text-right">
                  <p>{{ $t('booking_review.account_name') }}</p>
                </b-col>
                <b-col cols="6" class="ml-0 pl-1 text-primary text-left">
                  <p>{{ $t('booking_review.blue_services') }}</p>
                </b-col>
              </b-row>
            </b-card-text>
            <b-img
              v-if="image"
              center
              :src="image"
              height="200"
              alt="Center image"
              class="mb-3"
              style="border-radius: 10px"
              @click="showImage(image)"
            ></b-img>

            <b-button
              href="#"
              :variant="(!image || cannotSubmit) ? 'primary' : 'outline-primary'"
              @click="uploadImage"
              :disabled="isLoading"
              class="width_180"
            >
              <b-spinner small class="mr-2" v-if="isLoading"></b-spinner>
              {{ $t('booking_review.upload_payment_slip') }}</b-button
            >
            <input
              id="main_image"
              class="d-none"
              type="file"
              accept="image/*"
              name="photo"
              @change="importMainImage($event)"
            />
            <!-- <p v-if="image" class="mt-3">{{ image.name }}</p> -->
          </b-card>
        </b-row>
        <b-row class="justify-content-center">
          <p class="text-danger" v-if="image && !cannotSubmit">
            {{ $t('booking_review.your_booking_is_not_complete') }}
          </p>
        </b-row>
        <b-row class="justify-content-center">
          <b-button
            href="#"
            variant="primary"
            @click="toTop"
            class="width_180"
            :disabled="!image || cannotSubmit || cannotSubmitAgain"
          >
           <span style="font-size: 1rem;">{{ $t('booking_review.submit') }}</span></b-button
          >
        </b-row>
      </div>
    </div>
  </b-container>
  <!-- <b-container v-show="!showBookingReview" class="container-content card-container translateYg text-center">
  <b-spinner  variant="primary" label="Spinning" class="text-center "></b-spinner>
  </b-container> -->
</div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { Vue, Component, Watch } from 'vue-property-decorator'
import MaidDetail from '../../components/MaidDetail'
import AddressDetail from '../../components/Address'
import Moment from 'moment'
import ApiClient from '../../services'
import { MERCHANT_ID, PAYSOLUTION_URL, getReturnURL, getProductDetailFromType } from '../../config/constants'
import Constant, { ENABLE_APPLY_COUPON } from '../../config/booking'
import { post, isProduction, clone } from '../../utils'
import BookedCredits from '../../components/BookedCredits'
import { getPaySolutionCallbackURL, getEnvName } from '../../helpers/network'
import BaseModal from '../../components/Modal/BaseModal'
import { isUATEnv } from '../../helpers/network'
import Compressor from 'compressorjs'

import { equipmentMixin } from '../../mixins/booking/equipmentMixin'

import get from 'lodash/get'

const host = window.location.host
const env = getEnvName(host)

const BookingStore = createNamespacedHelpers('booking')
const UserStore = createNamespacedHelpers('user')
const AuthStore = createNamespacedHelpers('auth')
const postbackurl = getPaySolutionCallbackURL(env)
const returnurl = getReturnURL()
let lat_google, lng_google

Component.registerHooks(['beforeRouteEnter'])

@Component({
  computed: {
    ...BookingStore.mapState(['bookingReview', 'isLog']),
    ...BookingStore.mapState({
      currentPaymentBody: 'paymentBody',
    }),
    ...UserStore.mapState(['email', 'is_verified_phone']),
    ...AuthStore.mapState(['callBackPathAfterWelcomePage', 'isLoggedIn']),
  },
  methods: {
    ...BookingStore.mapMutations([
      'setPaymentBody',
      'setBookingReview',
      'setSlipURL',
      'setReduce',
      'setDiscountPrice',
    ]),
    ...AuthStore.mapMutations(['setCallBackPathAfterWelcomePage']),
    ...AuthStore.mapActions(['signIn']),
    ...UserStore.mapActions(['getUserProfile']),
    ...BookingStore.mapActions(['processBooking', 'preBooking', 'uploadSlip']),
  },

  components: {
    MaidDetail,
    AddressDetail,
    BookedCredits,
    BaseModal,
  },
  mixins: [equipmentMixin],
})
export default class BookingReview extends Vue {
  cannotSubmitAgain = false
  preBooking_result = null
  image = null
  slip = ''
  gaBacK = false
  payments = []
  canSelectDate = true
  time_can_pay = null
  paymentType = 'credit'
  isBank = false
  isSubmit = false
  timeAt = Moment(60 * 60 * 1000)
  countDown = null
  image_2 = null

  // ------------------------
  isLoading = false
  isClickConfirmBooking = false
  couponStatusLabel = ''
  coupon = ''
  discount = 0
  paymentValue = {
    merchantid: MERCHANT_ID,
    refno: undefined,
    customeremail: undefined,
    productdetail: undefined,
  }
  type = null
  is_used_promotion = false
  prevRoute = {
    name: '',
  }
  showBookingReview = false

  booking_review_id = null
  interval = null

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from
    })
  }

  async created() {
    if (!this.bookingReview.type) {
      let bookingState = JSON.parse(localStorage.bookingState)
      await this.setBookingReview(bookingState)
    } 

    window.addEventListener("beforeunload", this.clearCurrentBookingReview);
    window.addEventListener("load",this.setCurrentBookingReview());
    console.log(this.prevRoute)
    // console.log("router", this.$router.history);
    // this.applyCoupon();
  }

  beforeDestroy() {
    this.clearCurrentBookingReview()
  }

  setCurrentBookingReview(){
    let bookingState = JSON.parse(localStorage.bookingState)
    let currentBookingReview = localStorage.currentBookingReview ? JSON.parse(localStorage.currentBookingReview) : []
    this.booking_review_id = this.$route.query.temp_id || this.$route.query.booking_id
    if (!currentBookingReview.find((item) => item == this.booking_review_id)) {
      currentBookingReview.push(this.booking_review_id);
      localStorage.setItem('currentBookingReview', JSON.stringify(currentBookingReview));
    }

    if (currentBookingReview.filter((item) => item != this.booking_review_id && !!item).length > 0 && ( this.booking_review_id == bookingState.id || this.booking_review_id == bookingState.temp_id)) {
      this.$nextTick(() => {
        this.$root.$emit('bv::show::modal', 'warning-have-another-booking')
      })
    }
  }

  clearCurrentBookingReview() {
    let currentBookingReview = localStorage.currentBookingReview ? JSON.parse(localStorage.currentBookingReview) : []
    if (currentBookingReview.find((item) => item == this.booking_review_id)) {
      currentBookingReview = currentBookingReview.filter((item) => item != this.booking_review_id && !!item)
      localStorage.setItem('currentBookingReview', JSON.stringify(currentBookingReview))
    }
  }

  toMyBooking() {
    this.$router.push({ name: 'MyBooking' })
  }
  async toTop() {
    if(this.checkBookingSuspended()) return
    if(this.checkTimeCurrent()){
      this.$root.$emit('bv::show::modal', 'warning-invalid-datetime')
      return
    }

    this.cannotSubmitAgain = true
    this.setReduce(this.totalString)

    let result_3 = await this.preBooking(false)
    console.log('result bank ', result_3)

    if (result_3 && result_3.data) {
     try{
      this.preBooking_result = result_3.data
      console.log('prebooking result', this.preBooking_result)
      const result = await this.uploadSlip({
        image: this.image_2,
        booking_id: this.preBooking_result.id,
      })
      // console.log("this.paymentBody.refno", this.paymentBody.refno);
      isProduction() &&
        this.$gtag.event('conversion', {
          send_to: 'AW-685115829/6OegCM_e6eIBELWT2MYC',
          transaction_id: this.paymentBody.refno || '',
        })

      console.log('presult.slip_url', result.slip_url)
      // this.image = convert;
      if (result.slip_url) {
        localStorage.isRedirect = 'false'
        this.slip = result.slip_url
        this.isSubmit = true
        this.setSlipURL(this.slip)
        this.cannotSubmitAgain = false
        // window.scrollTo(0, 0);

        // console.log("string", this.totalString);
        const total = parseFloat(this.bookingReview.total * 1.07).toFixed(2)
        dataLayer.push({'event':'order_confirm','order_value':total});
        this.$router.push({ name: 'Slip Confirm'})
      }
     }catch(e){
      console.error('error sentry', e)
      this.cannotSubmitAgain = false
      // this.$root.$emit('bv::show::modal', 'warning-upload-image-fail')
     }
    } else {
      console.error('error sentry', result_3)
      this.cannotSubmitAgain = false
      // this.$root.$emit('bv::show::modal', 'alert-network-error')
    }
  }

  async mounted() {
    this.$store.commit('setIsLoading', true)
    console.log('CONDITION TYPEOF', typeof this.bookingReview.location.additional_details)
    localStorage.isFromBookingReview = 'booking-review'
    console.log('bookingReview', this.bookingReview.location)
    try {
      this.showBookingReview = false
      if (this.isLoggedIn) {
        await this.getPaymentHistory()
      }

      this.paymentValue.customeremail = this.email || 'noemail@bluuu.co'
      this.paymentValue.productdetail = this.booking_type
      this.bookingReview.total = this.bookingReview.amount
      // console.log('prevRoute', this.prevRoute)
      let booking_detail_local
      if(localStorage.booking_detail){
        booking_detail_local = JSON.parse(localStorage.booking_detail)
      }
      if (this.prevRoute.name === 'Home' || this.prevRoute.name === 'VerifyPhone') {
        this.paymentType = this.bookingReview.payment_method
        if (typeof this.bookingReview.coupon_body && this.bookingReview.coupon_body) {
          this.coupon = booking_detail_local.coupon_body.code
          await this.applyCoupon()
        }
        if (JSON.stringify(booking_detail_local.coupon_body) === 'true') {
          this.coupon = 'THAIWIN7'
          await this.applyCoupon()
        }
        if (typeof this.bookingReview.coupon_body && this.bookingReview.coupon_body && this.prevRoute.name === 'VerifyPhone' && localStorage.isRedirect == 'true') {
          await this.doBooking()
          // this.initMaps()
          return
        }
      }
      if(this.bookingReview.isUpdateBooking && parseInt(this.amount) == 0){
        await this.doBooking()
        return
      }
      console.log("onShowBookingReviewChanged",1);
      this.showBookingReview = true
      // this.initMaps()
    } catch (error) {
      this.showBookingReview = true
      console.log(error)
    }
  }

  initMaps(latitude, longtitude) {
    let lat = this.bookingReview.location.latitude
    let long = this.bookingReview.location.longitude
    // let center = new google.maps.LatLng(lat, long)

    // let maps = new google.maps.Map(this.$refs.gmaps, {
    //   zoom: 15,
    //   center: new google.maps.LatLng(lat, long),
    //   mapTypeId: google.maps.MapTypeId.ROADMAP,
    // })
    // let marker = new google.maps.Marker({
    //   position: center,
    //   map: maps,
    //   // visible: true, ไม่จำเป็นต้องเปิดก็ได้
    // })
    // marker.setMap(maps)
  }

  goBack() {
    this.$root.$emit('bv::hide::modal', 'booking-suspended-modal')
    this.$router.go(-1)
  }

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from
    })
  }

  get isMobileXS() {
    let x = false
    screen.width < 425 ? (x = true) : (x = false)
    return x
  }

  get showApplyCouponSection() {
    return ENABLE_APPLY_COUPON
  }

  get choseLessThanRecommended() {
    const { accommodation, duration } = this.bookingReview;

    if (!accommodation || !duration || accommodation.value === 'OTHERS') {
      return '';
    }

    const num = duration.value;
    let lessThan = '';

    const durationRecommendations = {
      CONDO_1_BR: { min: 3, max: 4 },
      CONDO_2_BR: { min: 4, max: 5 },
      CONDO_3_BR: { min: 5, max: 6 },
      CONDO_4_BR: { min: 6, max: 8 },
      HOUSE_1_STORY: { min: 3, max: 4 },
      HOUSE_2_STORIES: { min: 4, max: 6 },
      HOUSE_3_STORIES: { min: 6, max: 8 },
      OFFICE: { min: 3, max: 4 }
    };

    const recommendation = durationRecommendations[accommodation.value];

    if (recommendation) {
      if (num < recommendation.min) {
        lessThan = `${recommendation.min - num}-${recommendation.max - num}`;
      }
    }

    return lessThan === '' ? '' : `( ${this.$t('form_label.less_than_recommended_durations')} ${lessThan} ${this.$t('alias.hrs')} )`;
  }

  uploadImage() {
    document.getElementById('main_image').click()
  }
  compressImage(file) {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.6,
        success(result) {
          resolve(result)
        },
        error(e) {
          reject(e)
        },
      })
    })
  }
  showImage(item) {
    if (this.slip) {
      this.slip = item
      this.image = item
    } else {
      this.image = item
    }
    this.$root.$emit('bv::show::modal', 'slip-img')
  }
  async importMainImage(e) {
    this.image = null
    this.slip = null
    const file = e.target.files[0]

    if (!file) {
      this.isLoading = false
      return
    }

    if (file.type.indexOf('image') === -1) {
      // this.$root.$emit('bv::show::modal', 'warning-upload-image-fail')
      this.isLoading = false
      e.target.value = ''
      return
    }

    this.isLoading = true

    try {
      let convertedImage
      try{
        convertedImage = await this.compressImage(file)
      } catch (error) {
        console.error(error)
      }
      
      if (convertedImage) {
        this.image = URL.createObjectURL(convertedImage)
        this.image_2 = convertedImage
      } else {
        if (file.size > 5 * 1024 * 1024) { // 5 MB
          // this.$root.$emit('bv::show::modal', 'warning-upload-image-fail')
          e.target.value = ''
        } else {
          this.image = URL.createObjectURL(file)
          this.image_2 = file

          const image = new Image();
          image.src = this.image;

          image.onload = () => {
            console.log('Image loaded successfully');
          };
          image.onerror = () => {
            console.error('Error loading the image');
            // this.$root.$emit('bv::show::modal', 'warning-upload-image-fail')
            e.target.value = ''
            this.image = null
            this.image_2 = null
          };
        }
      }
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoading = false
    }
  }

  requireAuthIfNotLoggedIn() {
    if (!this.isLoggedIn) {
      this.$root.$emit('bv::show::modal', 'require-auth-modal')
      return false
    }
    return true
  }

  async doSignIn() {
    // this.setCallBackPathAfterWelcomePage('/booking-review')
    this.$root.$emit('bv::hide::modal', 'require-auth-modal')
    // this.signIn()
  }
  async getPaymentHistory() {
    try {
      const result = await ApiClient.paymentHistory({
        page: 1,
        limit: 20,
      })
      const { payment } = result.data

      this.payments = payment[0]

      const dateFormat = this.$i18n.locale === 'th' ? `DD MMM YYYY, H:mm` : `MMM DD, YYYY, H:mm`
      // let {time_pay, time_now} = null
      let time_pay = new Date(payment[0].create_datetime)
      let time_now = new Date()
      let time_can_pay = new Date(time_pay.getTime() + 60 * 60 * 24 * 1000)

      this.time_can_pay = Moment(time_can_pay).locale(this.$i18n.locale).format(dateFormat)
      this.$i18n.locale === 'th' ? this.time_can_pay + ' น.' : null
      if (time_now <= time_can_pay) {
        // ปิด function สำหรับทำรายการ 24 hr
        // this.canSelectDate = false
      } else {
        null
      }
      // console.log("can select", this.canSelectDate);
    } catch (e) {
      console.log(e.response)
    }
  }

  checkBookingSuspended(){
    const bookingState = JSON.parse(localStorage.bookingState)
    const isSuspended =  !(this.booking_review_id == bookingState.id || this.booking_review_id == bookingState.temp_id)
    if(isSuspended){
      this.$root.$emit('bv::show::modal', 'booking-suspended-modal')
    }
    return isSuspended
  }

  checkTimeCurrent() {
      const current_date = localStorage.getItem("currentDate") || new Date();
      const currentTime = new Date().getTime();
      const dateThreshold = 1000 * 60 * 60; // 1 hour in milliseconds
      const wrong_current_date = current_date && (Math.abs(new Date(current_date).getTime() - currentTime) > dateThreshold);
      this.dateTimeTimezone = this.getDateTimeTimezone()
      return wrong_current_date && !this.isAdmin
  }

  get isAdmin() {
    let adminToken = window.localStorage.getItem('admin_token')
    return this.isLoggedIn && adminToken && adminToken !== 'null'
  }

  async doBooking() {
    if(this.checkBookingSuspended()) return
    if(this.checkTimeCurrent()){
      this.$root.$emit('bv::show::modal', 'warning-invalid-datetime')
      return
    }
    localStorage.paymentType = this.paymentType
    this.saveBookingDetailToLocalStorage()
    this.setReduce(this.totalString)

    this.isClickConfirmBooking = true

    const canNext = this.requireAuthIfNotLoggedIn()
    let paymentBody = {
      ...clone(this.paymentBody),
      customeremail: this.$store.state.user.email || 'noemail@bluuu.co',
    }
    this.saveBookingDetailToLocalStorage()
    this.setPaymentBody(paymentBody)
    this.savePaymentBodyToLocalStorage(paymentBody)
    // console.log('eeee:user')
    if (!canNext) return

    await this.getUserProfile() // Ensure data payload

    paymentBody = {
      ...clone(this.paymentBody),
      customeremail: this.$store.state.user.email || 'noemail@bluuu.co',
    }

    this.setPaymentBody(paymentBody)

    this.saveBookingDetailToLocalStorage()
    this.savePaymentBodyToLocalStorage(paymentBody)
    // alert(this.bookingReview.total)
    if (this.bookingReview.total === 0) {
      this.$router.push({ name: 'PaymentResult' })
      return
    }
    this.isLoading = true
    if (this.paymentType === 'credit') {
      if (this.is_verified_phone) {
        // console.log("can't book",this.canSelectDate);
        // if (!this.canSelectDate) {
        //   this.$root.$emit('bv::show::modal', 'warning-cannotselectday')
        //   setTimeout(() => {
        //     this.$root.$emit('bv::hide::modal', 'warning-cannotselectday')
        //   }, 3000)
        //   await this.preBooking(false)
        //   // this.processBooking(false)
        // }
        // else {
        try {
          this.setReduce(this.totalString)
          let result_2 = await this.preBooking(false)
          console.log('result PAY ', result_2)
          if (result_2 && result_2.data.id) {
            const confirmPayment = {
              booking_id: result_2.data.id,
              ref_no: paymentBody.refno,
            }
            localStorage.confirmPayment = JSON.stringify(confirmPayment)
          } else {
            console.error('error sentry', result_2)
            this.isLoading = false
            // this.$root.$emit('bv::show::modal', 'alert-network-error')
            return
          }
          // console.log(this.bookingReview)
          // console.log(paymentBody);
          post(PAYSOLUTION_URL, {
              ...paymentBody,
              productdetail: getProductDetailFromType(paymentBody.productdetail)
            })
        } catch (e) {
          console.error('error sentry', e)
          // this.$root.$emit('bv::show::modal', 'warning-upload-image-fail')
          this.cannotSubmitAgain = false
        }
        // } // redirect to paysolution
      } else {
        this.setReduce(this.totalString)
        // await this.preBooking(false)
        this.setPaymentBody(paymentBody)
        this.$router.push({ name: 'VerifyPhone' })
      } ///upload Slip
    } else {
      if (this.is_verified_phone) {
        if(this.bookingReview.isUpdateBooking && parseInt(this.amount) == 0){
          this.showBookingReview = false
        }else{
          this.showBookingReview = true
        }
        // console.log("can't book",this.canSelectDate);
        // if (!this.canSelectDate) {
        //   this.$root.$emit('bv::show::modal', 'warning-cannotselectday')
        //   setTimeout(() => {
        //     this.$root.$emit('bv::hide::modal', 'warning-cannotselectday')
        //   }, 3000)
        //   //  this.processBooking(false);
        // }
        // else {
        if (this.$i18n.locale == 'th') {
          Moment.locale(this.$i18n.locale)
          // console.log('th')
        }

        // console.log("paymentBody", paymentBody);

        let a = false
        if(this.interval){
          clearInterval(this.interval)
        }

        this.countDown = Moment(60 * 60 * 1000)

        this.timeAt = Moment(this.$momentTZ().locale(this.$i18n.locale))
          .add(1, 'hours')
          .format('H:mm, ddd DD MMM YYYY')

        console.log('timeAt', this.timeAt, this.$momentTZ())
        this.isBank = true
        this.isLoading = false

        this.interval = setInterval(() => {
          if (this.countDown.format('mm:ss') == '00:00' && a && !this.isSubmit) {
            if (a) {
              a = false
              this.$router.push({ name: 'TimeOut' })
              clearInterval(this.interval)
            }
          } else {
            this.countDown = Moment(this.countDown.subtract(1, 'seconds'))
            a = true
          }
        }, 1000)
        //}
      } else {
        this.setReduce(this.totalString)
        // await this.preBooking(false)
        // this.processBooking(false);
        this.setPaymentBody(paymentBody)
        this.$router.push({ name: 'VerifyPhone' })
      }
    }
  }
  get countDownHour() {
    // let time = null;
    // if (this.isBank) {
    //   time = Moment(60 * 60 * 1000);
    // } else {
    //   time = null;
    // }
    // console.log("countDownHour");
    if (this.$i18n.locale === 'en') {
      return `${this.countDown.format('mm:ss')} mins`
    } else return `${this.countDown.format('mm:ss')} นาที`
  }
  get cannotSubmit() {
    let cannotSubmit = false
    if (this.countDown.format('mm:ss') == '00:00') {
      cannotSubmit = true
    }
    return cannotSubmit
    // let time = null;
    // if (this.isBank) {
    //   time = Moment(60 * 60 * 1000);
    // } else {
    //   time = null;
    // }
    // console.log("countDownHour");
    // if (this.$i18n.locale === "en") {
    //   return `${this.countDown.format("mm")} mins`;
    // } else return `${this.countDown.format("mm")} นาที`;
  }

  @Watch('isLoggedIn')
  async onIsLoggedInChanged(newval) {
    if (newval && this.isClickConfirmBooking) {
      this.isLoading = true
      if (!this.bookingReview.coupon_body) {
        await this.doBooking()
      }
    }
  }

  @Watch('email')
  async onIsEmailChanged(newval) {
    if (this.isLoggedIn && this.bookingReview.coupon_body) {
      this.isLoading = true
      let booking_detail_local = JSON.parse(localStorage.booking_detail)
      this.coupon = booking_detail_local.coupon_body.code
      await this.applyCoupon()
      this.isLoading = false
      if (typeof this.bookingReview.coupon_body && this.bookingReview.coupon_body && this.isClickConfirmBooking) {
          await this.doBooking()
      }
    }
  }

  @Watch('showBookingReview')
  async onShowBookingReviewChanged(newval) {
    this.$store.commit('setIsLoading', !newval)
  }

  savePaymentBodyToLocalStorage(paymentBody) {
    window.localStorage.setItem('payment_body', JSON.stringify(paymentBody))
  }

  saveBookingDetailToLocalStorage() {
    if(!this.bookingReview.isUpdateBooking || (this.bookingReview.isUpdateBooking && parseInt(this.amount) > 0)){
      this.paymentType === 'credit'
      ? (this.bookingReview.payment_status = 'ONLINE_BANKING_WAITING')
      : (this.bookingReview.payment_status = 'BANK_TRANSFER_WAITING')
    }
    // console.log("this.bookingReview", this.bookingReview);

    window.localStorage.setItem('booking_detail', JSON.stringify(this.bookingReview))
  }

  get headerText() {
    return this.$t('booking_review.heading')
  }

  get confirmButtonText() {
    return this.bookingReview.isUpdateBooking
      ? this.$t('button.confirm_update')
      : this.$t('button.confirm_booking')
  }

  get isBookingOneTime() {
    return this.bookingReview.type === 'one-time'
  }

  get paymentBody() {
    const refno = Moment().format('X')
    // const total =  this.totalString
    const total = isProduction() ? this.totalString : '1'
    const { merchantid, customeremail, productdetail } = this.paymentValue
    const postbackParams = `?merchantid=${merchantid}&status=completed&refno=${refno}&customeremail=${encodeURIComponent(customeremail)}&productdetail=${productdetail}&total=${total}`

    // return { ...this.paymentValue, refno: Moment().format('X'), total: this.totalString }
    // const total = '1'
    if (this.bookingReview.isUpdateBooking) {
      return {
        ...this.paymentValue,
        refno: this.bookingReview.total > 0 ? refno : null,
        total,
        coupon: this.coupon,
        postbackurl: postbackurl + postbackParams,
        returnurl,
      }
    }
    return {
      ...this.paymentValue,
      refno,
      total,
      coupon: this.coupon,
      postbackurl: postbackurl + postbackParams,
      returnurl,
    }
  }

  get bookingType() {
    if (!this.bookingReview.type) return ''
    return this.bookingReview.type === 'one-time'
      ? this.$t('commons.book_one_time')
      : this.$t('commons.book_multi_package')
  }

  get additional() {
    const additionals = this.bookingReview.additional.map((val) => {
      return Constant.ADDITIONAL_INFO.find((option) => option.value === val).text
    })
    return additionals.map((text) => this.$t(`check_box.${text}`))
  }

  get amount() {
    return parseFloat(this.bookingReview.amount).toFixed(2)
  }

  get vat() {
    return (parseFloat(this.bookingReview.total) * 0.07).toFixed(2)
    //return parseFloat(0).toFixed(2) // fix me (use above value when we calculate total including vat)
  }

  get totalExcludeVat() {
    return (parseFloat(this.totalString) * 0.93).toFixed(2)
  }

  get totalDiscount() {
    return parseFloat(this.discount).toFixed(2)
  }

  get totalString() {
    const total = parseFloat(this.bookingReview.total * 1.07).toFixed(2)
    // dataLayer.push({'event':'order_confirm','order_value':total});
    return total
    //return parseFloat(this.bookingReview.total).toFixed(2) // fix me (Use above value)
  }

  get fullName() {
    const maid = this.bookingReview.maid
    if (maid) {
      return maid.user.first_name + ' ' + maid.user.last_name
    }
    return ''
  }

  get service() {
    const serviceText = this.bookingReview.services ? this.bookingReview.services.text : ''
    if (this.bookingReview.calendar.length === 0) return serviceText
    const startDate = this.$momentTZ(this.bookingReview.calendar[0].start_datetime)
    const endDate = this.$momentTZ(this.bookingReview.calendar[0].end_datetime)

    // Fix me (services)  ${startDate.format('H:mm')} - ${endDate.format('H:mm')}
    return `${serviceText} `
  }

  get serviceTime() {
    if (this.bookingReview.calendar.length === 0) return serviceText
    const startDate = this.$momentTZ(this.bookingReview.calendar[0].start_datetime)
    const endDate = this.$momentTZ(this.bookingReview.calendar[0].end_datetime)

    return `${startDate.format('H:mm')} - ${endDate.format('H:mm')}`
  }

  get location() {
    const locationPin = this.bookingReview.location.main_text
    return `${locationPin} `
  }

  get place() {
    const secondPin = this.bookingReview.location.secondary_text
    return `${secondPin} `
  }

  get additional_details() {
    const additionalDetails = this.bookingReview.location.additional_details
    console.log('addigtoinal adial', additionalDetails)
    if (additionalDetails) {
      return `${additionalDetails}`
    }
    return null
  }

  get booking_type() {
    let isOTA = this.bookingReview.type === 'one-time' && this.bookingReview.method === 0
    let isOTM = this.bookingReview.type === 'one-time' && this.bookingReview.method === 1
    if (isOTA) {
      return Constant.ONE_TIME_AUTO_SELECT
    }
    if (isOTM) {
      return Constant.ONE_TIME_MANUAL_SELECT
    }

    return Constant.MULTI_PACKAGE
  }

  get startDateLabel() {
    if (this.bookingReview.calendar.length === 0) return date.format(template)
    const date = Moment(this.bookingReview.calendar[0].start_datetime)
    const template = this.$i18n.locale === 'th' ? 'ddd DD MMM YYYY' : 'ddd MMM DD, YYYY'
    return date.format(template)
  }
  // discount for front only (ex Jan 31 2021)
  async applyCoupon() {
    let canUse =
      this.coupon.toUpperCase() === 'WELCOME10' || this.coupon.toLowerCase() === 'welcome10'
    let body = {
      code: this.coupon.toUpperCase(),
      price: this.bookingReview.amount,
      used: false,
      booking_type: this.booking_type,
    }
    // console.log('booking_type', this.booking_type)

    let date = new Date()
    let timestamp_now = date.getTime()

    let timestamp_end = 1655312400000

    if (canUse && this.booking_type !== 'MULTI_PACKAGE') {
      if (!this.isLoggedIn) {
        this.discount = this.bookingReview.amount * 0.1
        this.bookingReview.total = this.bookingReview.amount - this.discount
        this.bookingReview.coupon_body = body
        this.saveBookingDetailToLocalStorage()
        this.couponStatusLabel = this.$t('booking_review.coupon_applied')

        return
      }
      let re = null

      try {
        re = await ApiClient.applyCoupon(body)
        let { discount_price } = re.data
        if (discount_price) {
          this.discount = discount_price
          this.bookingReview.total = this.bookingReview.amount - this.discount
          this.bookingReview.coupon_body = body
          this.setDiscountPrice(this.discount)
          this.couponStatusLabel = this.$t('booking_review.coupon_applied')
          this.saveBookingDetailToLocalStorage()
        }
      } catch (e) {
        this.clearPromotion()
        if (e.response.data.error === 'first_time_booking_only') {
          this.couponStatusLabel = this.$t('error.coupon.first_time_booking_only')
        } else {
          this.couponStatusLabel = this.$t('error.coupon.invalid_code')
        }
      }
    }
    else if (this.coupon.toUpperCase() === 'THAIWIN7') {
      this.bookingReview.coupon_body = true
      // let timestamp_end = 1640995199000
      if (timestamp_now < timestamp_end && this.coupon.toUpperCase() === 'THAIWIN7') {
        this.discount = (this.bookingReview.amount * 7) / 100
        this.setDiscountPrice(this.discount)
        this.bookingReview.total = this.bookingReview.amount - this.discount
        this.couponStatusLabel = this.$t('booking_review.coupon_applied')
        this.saveBookingDetailToLocalStorage()
      } else {
        this.couponStatusLabel = this.$t('booking_review.coupon_invalid')
        this.clearPromotion()
      }
    }
    ///THAIWIN7 PROMOTION
    // else if (this.coupon) {
    //   let date = new Date()
    //   let timestamp_now = date.getTime()

    //   let timestamp_end = 1651363200000 //1 May 2022

    //   // let timestamp_end = 1640995199000
    //   if (timestamp_now < timestamp_end && this.coupon.toUpperCase() === 'THAIWIN7') {
    //     this.discount = (this.bookingReview.amount * 7) / 100
    //     this.setDiscountPrice(this.discount)
    //     this.bookingReview.total = this.bookingReview.amount - this.discount
    //     this.bookingReview.is_used_promotion = false
    //     this.is_used_promotion = false

    //     this.couponStatusLabel = this.$t('booking_review.coupon_applied')
    //     this.saveBookingDetailToLocalStorage()
    //
    //   } else {
    //     this.clearPromotion()
    //   }
    // }
    else {
      if (this.booking_type == 'MULTI_PACKAGE') {
        this.couponStatusLabel = this.$t('error.coupon.first_time_booking_only')
        this.clearPromotion()
        return
      } else {
        this.couponStatusLabel = this.$t('error.coupon.invalid_code')
        this.clearPromotion()
      }
    }
  }

  get isApplySuccess() {
    return this.couponStatusLabel === this.$t('booking_review.coupon_applied')
  }
  clearPromotion() {
    this.discount = 0
    this.bookingReview.is_used_promotion = false
    this.is_used_promotion = false
    this.bookingReview.total = this.bookingReview.amount
    this.bookingReview.coupon_body = null
    this.saveBookingDetailToLocalStorage()
  }

  get equipment() {
    const equipmentOption = this.getDefaultEquipmentOption({
      bring_equipment: get(this.bookingReview, 'equipment.bring_equipment', false),
    })

    const text = equipmentOption.text.split(') ')[1] // remove price (+xx THB)

    return { ...equipmentOption, text }
  }

  dateTimeTimezone = ''
  getDateTimeTimezone() {
    const d = new Date();

    // Get date components
    const day = d.getDate();
    const month = d.getMonth() + 1; // Month is zero-based, so add 1
    const year = d.getFullYear();

    // Get time components
    const hours = d.getHours();
    const minutes = d.getMinutes();

    // Get timezone offset in minutes
    const timezoneOffset = d.getTimezoneOffset();

    // Convert timezone offset to hours and minutes
    const absTimezoneOffsetHours = Math.abs(timezoneOffset / 60);
    const timezoneOffsetHours = Math.floor(absTimezoneOffsetHours);
    const timezoneOffsetMinutes = Math.abs(timezoneOffset % 60);

    // Determine the sign of the timezone offset
    const timezoneSign = timezoneOffset >= 0 ? '-' : '+';

    // Format the date/time and timezone
    const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
    const formattedTimezone = `(GMT${timezoneSign}${timezoneOffsetHours < 10 ? '0' : ''}${timezoneOffsetHours}:${timezoneOffsetMinutes < 10 ? '0' : ''}${timezoneOffsetMinutes})`;

    return `${formattedDateTime} ${formattedTimezone}`;
  }
}
</script>

<style scoped lang="scss">
.text-timestamp {
  font-size: 12px;
  margin-top: 10px;
  opacity: 0.7;
}

.width-image-modal {
  width: -webkit-fill-available;
  height: inherit;
}
.fullname {
  margin-top: 20px;
}

.date,
.cleaning-type {
  margin: 0;
}
.translateYg {
  transform: translateeY(100px) !important;
}
.warning-confirm {
  text-align: right;
}
.description {
  word-wrap: break-word;
}

.text-grey {
  color: #757575;
}

.warning-maid {
  font-size: 0.8rem;
}

.avatar {
  width: 70px;
  height: 70px;
  border-radius: 64px;
}
.cc-number {
  height: 24px;
  display: flex;
  align-items: center;
}
.width_180 {
  width: 180px !important;
}

.payment-detail {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  margin: 1em 0;
  font-size: 16px;
  color: #333333;
}
#map {
  height: 350px;
  // background: red;
}
@media screen and (max-width: 1199px) {
  .input-text-area {
    &-1 {
      display: none;
    }
  }
}
@media screen and (min-width: 1200px) {
  .input-text-area {
    &-2 {
      display: none;
    }
  }
}

@media screen and (min-width: 426px) {
  .input-text-area {
    &-2 {
      display: none;
    }
  }

  .img_rigth {
    position: relative;
    right: -50%;
  }
}

.map-container {
  width: 100%;
  display: flex;
}

.map-iframe {
  width: 79%;
  height: 350px;
  margin-left: 24px;
}

@media screen and (max-width: 1200px) {
  .map-iframe {
    width: 100%;
    margin-left: 0;
  }
}
</style>
